<h2 mat-dialog-title i18n>Create model</h2>
<mat-dialog-content>
	<div class="content">
		<app-model-form creationMode (modelChange)="model = $event" (formIsValid)="modelFormIsValid = $event" [reset]="resetForm"></app-model-form>

		<div class="image selectable" (click)="triggerFileInput()" 
			#dropZone (dragover)="onDragOver($event)" (dragleave)="onDragLeave($event)" (drop)="onDrop($event, true)">
			<button mat-stroked-button class="preboxes" (click)="preboxes = !preboxes; $event.stopPropagation()" [class.selected]="preboxes">
				<mat-icon>{{ preboxes ? 'auto_fix_high' : 'auto_fix_off' }}</mat-icon>
				{{ preboxes ? 'Preboxes enabled' : 'Preboxes disabled'}}
			</button>

			@if (mainImageUrl) {
				<img [src]="mainImageUrl" draggable="false">
				<button mat-stroked-button class="image-button" (click)="triggerFileInput(); $event.stopPropagation()">
					<mat-icon>image</mat-icon>
					Change image
				</button>
			} @else {
				<div class="image-placeholder">
					<mat-icon>image</mat-icon>
					<span i18n>Insert by clicking or drag/drop an image*</span>
				</div>
			}
		</div>
	</div>

	@if (loading) {
		<mat-divider></mat-divider>
		Loading...
		<mat-progress-bar mode="determinate" [value]="loadingState"></mat-progress-bar>
	}

</mat-dialog-content>
<mat-dialog-actions>
	<button mat-button mat-dialog-close i18n>Cancel</button>
  <button mat-raised-button [disabled]="!canConfirm" (click)="confirm()" i18n>Confirm</button>
</mat-dialog-actions>

<input #fileInput type="file"
name="file" accept="image/png, image/jpeg" (change)="importImage({event: $event})"
	style="display: none" />