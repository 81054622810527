import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Language } from 'src/app/models/model/language.model';
import { EnvironmentService } from '../../environment.service';

@Injectable({
  providedIn: 'root'
})
export class LanguageModelCrudService {
  private baseUrl = `${this.environment.apiUrl}/list/language`;

  constructor(private http: HttpClient, private environment: EnvironmentService) { }

  // Get
  getLanguages(): Observable<Language[]> {
    return this.http.get<Language[]>(this.baseUrl);
  }
}
