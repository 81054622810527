import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatDialogState } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { firstValueFrom } from 'rxjs';
import { FULL_SCREEN_DIALOG_CONFIG } from 'src/app/models/dialog.model';
import { Model } from 'src/app/models/model/model.model';
import { ModelCrudService } from 'src/app/services/crud/model/model-crud.service';
import { DialogConfirmChoiceDialogComponent } from 'src/app/shared/dialogs/dialog-confirm-choice-dialog/dialog-confirm-choice-dialog.component';

export interface DialogModelsData {
  // Add sortableList at the left of the view to select nodes
  sortableList?: boolean;
  // When you choose a selection
  modelsToList?: Model[];
  // When you want to not show models to avoid duplicate values
  modelIdsToHide?: string[];
  // Change the title (add or remove)
  title?: string;
  // These keys are for filter models
  filters?: Partial<{
    categoryId: number;
    docTypeId: number;
    languageId: number;
    page: string;
  }>;
  // Single or multiple selection (by default to multiple)
  singleSelection?: boolean;
  // Set dialog after confirm button to validate all interaction
  confirmBeforeValidate?: boolean;
  // Show detailed models
  showDetailedModels?: boolean;
  // Model creation option
  createModelOption?: boolean;
  // DisableClose
  disableClose?: boolean
}

export interface DialogModelsOutput {
  models?: Model[],
  createModel?: boolean
}

@Component({
  selector: 'app-dialog-models-dialog',
  templateUrl: './dialog-models-dialog.component.html',
  styleUrl: './dialog-models-dialog.component.scss'
})
export class DialogModelsDialogComponent implements OnInit {
  models: Model[] = [];
  detailedModels: Model[] = [];
  selectedModels: Model[] = [];

  // Base
  globalSelectedModels: Model[] = [];

  loading: boolean = false;

  hiddenList: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<DialogModelsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogModelsData,
    private modelApi: ModelCrudService,
    private snackbar: MatSnackBar,
    private matDialog: MatDialog) 
  {}

  async ngOnInit() {
    let models: Model[] = [];
    this.loading = true;
    try {
      if (this.data?.modelsToList) {
        models = this.data?.modelsToList ?? [];
      } else {
        models = await firstValueFrom(this.modelApi.getModels(this.data?.filters)) ?? [];
      }
      if (this.data?.modelIdsToHide) {
        models = models.filter(model => !this.data.modelIdsToHide!.includes(model.id));
      }
      this.models = models;

    } catch (error) {
      this.snackbar.open((error as Error).message);
    } finally {
      this.loading = false;
    }
  }

  confirm() {
    if (this.data.confirmBeforeValidate) {
      const confirmDialogRef = this.matDialog.open(DialogConfirmChoiceDialogComponent, { 
        data: { message: $localize`Are you sure ?` }
      });
      confirmDialogRef.afterClosed().subscribe(confirmed => {
        if (confirmed) {
          this.dialogRef.close({models: this.selectedModels});
        }
      });
    } else {
      this.dialogRef.close({models: this.selectedModels});
    }
  }

  showSelectedModels() {
    this.matDialog.open(DialogModelsDialogComponent, { 
      data: <DialogModelsData>{ modelsToList: this.globalSelectedModels, showDetailedModels: false }, ...FULL_SCREEN_DIALOG_CONFIG
    });
  }

  defineData(models: Model[]) {
    this.detailedModels = models;
    this.defineSelection(models);
  }

  defineSelection(models: Model[]) {
    this.selectedModels = [];
    for (const model of models) {
      const found = this.globalSelectedModels.find(modelToFound => modelToFound.id === model.id);
      if (found) {
        this.selectedModels.push(found);
      }
    }
  }

  changeModels(event: any) {
    if (!this.data.sortableList) {
      this.selectedModels = event;
    }
  }

  addModels(models: Model[]) {
    if (this.data.sortableList && models.length > 0) {
      this.globalSelectedModels.push(...models);
      // Avoid duplicated data
      this.globalSelectedModels = this.globalSelectedModels.filter(
        (obj, index, self) => index === self.findIndex(o => o.id === obj.id)
      );
      this.defineSelection(this.detailedModels);
    }
  }

  createModel() {
    this.dialogRef.close(<DialogModelsOutput>{createModel: true});
  }

  removeModels(models: Model[]) {
    if (this.data.sortableList && models.length > 0) {
      for (const removedModel of models) {
        this.globalSelectedModels = this.globalSelectedModels.filter(selModel => selModel.id !== removedModel.id);
      }
      this.defineSelection(this.detailedModels);
    }
  }
}
