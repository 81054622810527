import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { firstValueFrom, Observable } from 'rxjs';
import { Language } from 'src/app/models/model/language.model';
import { DocType, Model } from 'src/app/models/model/model.model';
import { DocTypeModelCrudService } from 'src/app/services/crud/model/doc-type-crud.service';
import { LanguageModelCrudService } from 'src/app/services/crud/model/language-crud.service';

@Component({
  selector: 'app-model-form',
  templateUrl: './model-form.component.html',
  styleUrl: './model-form.component.scss'
})
export class ModelFormComponent implements OnInit, OnChanges {
  @Input() model?: Model;
  @Input({transform: coerceBooleanProperty}) creationMode: boolean = false;
  @Input() reset?: boolean;

  @Output() modelChange = new EventEmitter<Model>();
  @Output() formIsValid = new EventEmitter<boolean>();

  modelForm!: FormGroup;

  // * Lists of choices
  languages$?: Observable<Language[]>;  
  types$?: Observable<DocType[]>;

  automaticName: boolean = false;

  constructor(
    private fb: FormBuilder,
    private docTypeApi: DocTypeModelCrudService,
    private languageApi: LanguageModelCrudService
  ) {
}

  async ngOnInit() {
    this.types$ = this.docTypeApi.getdocTypes();
    this.languages$ = this.languageApi.getLanguages();
    
    this.initForms();

    this.modelForm.valueChanges.subscribe((model: Model) => {
      if (this.automaticName) this.updateName();
      
      this.emitValues(model);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['model']) {
      this.updateForm();
    }
    if (changes['creationMode'] && this.creationMode) {
     this.automaticName = true;
    }
    if (changes['reset'] && this.reset) {
      this.initForms();
     }
  }
  
  emitValues(model: Model) {
    this.modelChange.emit(model);
    this.formIsValid.emit(this.modelForm.valid);
  }
  
  initForms() {
    this.modelForm = this.fb.group({
      name: ['', Validators.required],
      page: ['', Validators.required],
      type: ['graphic', Validators.required],
      languageId: [Validators.required],
      docTypeId: [Validators.required],
      validityDate: [],
      mobile: [false],
      public: [false],
      shiny: [false]
    });
  }
  
  updateForm() {
    if (this.modelForm && this.model) {
      this.modelForm.patchValue(<Model>{
        languageId: this.model.language!.id,
        page: this.model.page,
        docTypeId: this.model.docType!.id,
        name: this.model.name,
        type: this.model.type ?? '',
        validityDate: this.model.validityDate,
        mobile: this.model.mobile ?? false,
        shiny: this.model.shiny ?? false,
        public: this.model.public ?? false
      });
    }
  }

  toggle(key: string) {
    this.modelForm.patchValue({[key]: !this.modelForm.get(key)!.value});
  }

  async updateName() {
    const docTypeId = this.modelForm.get('docTypeId')?.value;
    const languageId = this.modelForm.get('languageId')?.value;
    const page = this.modelForm.get('page')?.value ?? '';

    const docType = (await firstValueFrom(this.types$!)).find(type => type.id === docTypeId)?.name ?? '';
    const language = (await firstValueFrom(this.languages$!)).find(language => language.id === languageId)?.code ?? '';

    if ((docType || page) && language) {
      this.modelForm.patchValue({name: `${docType}${page}_${language}`}, { emitEvent: false });
    } else {
      this.modelForm.patchValue({name: ''}, { emitEvent: false });
    }
  }

  async switchAutoName() {
    this.automaticName = !this.automaticName;
    if (this.automaticName) {
      await this.updateName();
      this.emitValues(<Model>this.modelForm.value);
    }
  }
}
