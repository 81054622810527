@if (authService.isLogging || authService.error) {
	<div class="container-loading">
		<div class="container-welcome">
			<mat-icon class="large-icon">photo_filter</mat-icon>
			<span>Manager</span>
		</div>

		@if (authService.error) {
			<span class="mat-title-medium">Connection not possible, try to reconnect or contact an administrator.</span>
			<button mat-button (click)="authService.login()">
				<mat-icon>refresh</mat-icon>
				<span>Try to reconnect</span>
			</button>
		} @else {
			<mat-progress-bar mode="indeterminate" [class.visible]="showLoader"></mat-progress-bar>
		}
		
	</div>
} @else {
	<app-header></app-header>
	<router-outlet></router-outlet>
	<p-toast></p-toast>
}