import { ENTER } from '@angular/cdk/keycodes';
import { Injectable, Renderer2, RendererFactory2, inject } from '@angular/core';
import Konva from 'konva';
import { Group } from 'konva/lib/Group';
import { Layer } from 'konva/lib/Layer';
import { Stage } from 'konva/lib/Stage';
import { Text } from 'konva/lib/shapes/Text';

@Injectable({
  providedIn: 'root'
})
export class KonvaRenameBoxService {
	private renderer: Renderer2;

  private selectedText?: Text;
  private editionFrame?: HTMLInputElement;

  constructor() {
		this.renderer = inject(RendererFactory2).createRenderer(null, null);
	 }

   openEditionFrame(layer: Layer, stage: Stage, transformer: Konva.Transformer, group: Group) {
    const text = (group.findOne('Text') as Konva.Text);
    const textValue = text.text();
    text.text('');
    this.selectedText = text;
    // Text edition creation
    const input: HTMLInputElement = this.renderer.createElement('input');
    this.renderer.appendChild(stage.container(), input);
    this.renderer.setProperty(input, 'value', textValue);
    this.renderer.setStyle(input, 'position', 'absolute');
    this.renderer.setStyle(input, 'background', 'transparent');
    this.renderer.setStyle(input, 'font-weight', 'bold');
    this.renderer.setStyle(input, 'padding', '5px');
    this.renderer.setStyle(input, 'box-sizing', 'border-box');
    this.renderer.setStyle(input, 'resize', 'none');
    this.renderer.setStyle(input, 'top', group.position().y + 'px');
    this.renderer.setStyle(input, 'left', group.position().x + 'px');
    this.renderer.setStyle(input, 'width', text.width() + 'px');
    this.renderer.setStyle(input, 'height', text.height() + 'px');
    input.focus();
    this.editionFrame = input;

    this.renderer.listen(input, 'keydown', (event) => {
      if (event.keyCode === ENTER) {
        this.applyChanges(stage);
      }
    });
    // Store this one.
    layer.draw();
  }

  closeEditionFrame(stage: Stage) {
    if (this.editionFrame) {
      this.renderer.removeChild(stage.container(), this.editionFrame);
      delete this.selectedText;
      delete this.editionFrame;
    }
  }

  applyChanges(stage: Konva.Stage) {
    if (this.selectedText && this.editionFrame) {
      this.selectedText.text(this.editionFrame.value);
      this.closeEditionFrame(stage);
    }
  }

  
}
