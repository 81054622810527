import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { SchemaKeys, SchemaLevel, SchemaNode, SchemaSelection } from '../../models/schema/schema.model';
import { Schema } from '@doclab/schema';
import { ConfigModelService } from './config-model.service';
import { NOT_ATTRIBUTED } from '../../models/model/category.model';

@Injectable({
  providedIn: 'root'
})
export class SchemaSelectionService {
  private selectedNodeSubject: BehaviorSubject<SchemaSelection | undefined> = new BehaviorSubject<SchemaSelection | undefined>(undefined);
	selectedNode$ = this.selectedNodeSubject.asObservable();

  constructor(public configModelService: ConfigModelService) { }

  defineSelection(schema: Schema, keys: SchemaKeys): SchemaSelection | undefined {
    let selection: SchemaSelection | undefined;

    const level = this.getSchemaLevel(keys);
    const refinedKeys = this.refineKeysByLevel(keys, level);
		const nodes = this.getSchemaContentByLevel(schema, level, refinedKeys);
    
		if (nodes && nodes.content) {
			let category = '';
			if (refinedKeys.fileKey && refinedKeys.docKey) {
				category = schema.files[refinedKeys.fileKey].documents[refinedKeys.docKey].classes?.[0] ?? NOT_ATTRIBUTED;
			}
			const linkedModels = this.configModelService.findLinkedModels(refinedKeys, level);
			selection = { 
        level, 
        keys, 
        content: nodes.content, 
        parentContent: nodes.parentContent, 
        category, 
        linkedModels, 
      };
		}
		return selection;
	}

  /**
   * Updates the selection and detects if it is pointing to an existing element otherwhise it's reset.
   * @param schema 
   */
  refreshSelection(schema: Schema) {
    const currSelection: SchemaSelection | undefined = this.getCurrentSelection();
		if (currSelection) {
      const keysExist = this.keysExist(schema, currSelection.keys, currSelection.level);
			if (keysExist) {
        // Select node again to refresh
				this.selectNode(schema, currSelection.keys);
			} else {
        this.clearSelection();
      }
		}
  }

  getCurrentSelection() {
    return this.selectedNodeSubject.getValue();
  }

  selectNode(schema: Schema, keys: SchemaKeys) {
    const selection = this.defineSelection(schema, this.purifyKeys(keys));
    this.selectedNodeSubject.next(selection);
  }

  clearSelection() {
    this.selectedNodeSubject.next(undefined);
  }

  /**
   * Get the selected element from the schema to get contextual data.
   * @param schema 
   * @param level 
   * @param keys 
   * @returns 
   */
  getSchemaContentByLevel(schema: Schema, level: SchemaLevel, keys: SchemaKeys): { 
    content: SchemaNode | undefined, 
    parentContent: SchemaNode | undefined
  } {
		let content: SchemaNode | undefined;
    let parentContent: SchemaNode | undefined;
		switch (level) {
			case 'file':
				content = schema.files?.[keys.fileKey!];
				break;
				case 'document':
					content = schema.files?.[keys.fileKey!]?.documents?.[keys.docKey!];
          parentContent = schema.files?.[keys.fileKey!];
				break;
				case 'page':
					content = schema.files?.[keys.fileKey!]?.documents?.[keys.docKey!]?.pages?.[keys.pageKey!];
          parentContent = schema.files?.[keys.fileKey!]?.documents[keys.docKey!];
				break;
			default:
				break;
		}
		return { content, parentContent };
	}

  getModelsFromSelection(selection: SchemaSelection) {
		const foundLinkedModels = this.configModelService.findLinkedModels(selection.keys, selection.level);
		return foundLinkedModels.map(linkedModel => linkedModel.model);
	}

  /**
   * Retrieve schema level from keys
   * @param keys 
   * @returns 
   */
  getSchemaLevel(keys: SchemaKeys): SchemaLevel {
    if (keys.fieldKey) {
        return 'field';
    } else if (keys.pageKey) {
        return 'page';
    } else if (keys.docKey) {
        return 'document';
    } else if (keys.fileKey) {
        return 'file';
    }
    return 'file';
  }

  /**
   * Change level from input keys
   * @param keys 
   * @param level 
   */
  refineKeysByLevel(keys: SchemaKeys, level: SchemaLevel): SchemaKeys {
    switch (level) {
      case 'file': 
        return this.omitKeys(keys, ['docKey', 'pageKey', 'fieldKey']);
      case 'document':
        return this.omitKeys(keys, ['pageKey', 'fieldKey']);
      case 'page':
        return this.omitKeys(keys, ['fieldKey']);
      default:
        return keys;
    }
  }

  omitKeys<T extends Record<string, any>, K extends keyof T>(obj: T, keysToRemove: K[]): Omit<T, K> {
    const result = { ...obj };
    keysToRemove.forEach(key => {
        delete result[key];
    });
    return result;
  }

  /**
   * Remove useless keys like undefined or null keys.
   * @param obj 
   * @returns 
   */
  purifyKeys<T extends object>(obj: T): Partial<T> {
    return Object.keys(obj).reduce((acc, key) => {
      const value = obj[key as keyof T];
      if (value !== undefined && value !== null) {
        acc[key as keyof T] = value;
      }
      return acc;
    }, {} as Partial<T>);
  }

  changeKeyFromLevel(keys: SchemaKeys, newKey: string, level: SchemaLevel): SchemaKeys {
    switch (level) {
      case 'file': 
        return {...keys, fileKey: newKey};
      case 'document': 
        return {...keys, docKey: newKey};
      case 'page': 
        return {...keys, pageKey: newKey};
      case 'field':
        return {...keys, fieldKey: newKey};
      default:
        return keys;
    }
  }

  unselect() {
    this.selectedNodeSubject.next(undefined);
  }

  keysExist(schema: Schema, keys: SchemaKeys, level: SchemaLevel): boolean | undefined {
    switch (level) {
      case 'file': 
        return schema.containsFile(keys.fileKey!) ?? false;
      case 'document': 
        return schema.files?.[keys.fileKey!]?.containsDocument(keys.docKey!) ?? false;
      case 'page': 
        return schema.files?.[keys.fileKey!]?.documents?.[keys.docKey!]?.containsPage(keys.pageKey!) ?? false;
      case 'field':
        return schema.files?.[keys.fileKey!]?.documents?.[keys.docKey!]?.pages?.[keys.pageKey!]?.containsField(keys.fieldKey!) ?? false;
      default:
        return false;
    }
  }
}
