import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { User } from 'src/app/models/user.model';
import { EnvironmentService } from '../environment.service';

@Injectable({
  providedIn: 'root'
})
export class UserCrudService {
  private baseUrl = `${this.environment.apiUrl}/user`;

  constructor(private http: HttpClient, private environment: EnvironmentService) {}

  // Get
  getUsers(): Observable<User[]> {
    return this.http.get<User[]>(this.baseUrl);
  }
  getUserById(userId: number): Observable<User> {
    return this.http.get<User>(`${this.baseUrl}/${userId}`);
  }
  getUserByMail(userMail: string): Observable<User> {
    return this.http.get<User>(`${this.baseUrl}/findByEmail/${userMail}`);
  }
  // Put
  editUser(userId: number, user: User): Observable<any> {
    return this.http.put<User>(`${this.baseUrl}/${userId}`, user);
  }
  // Post
  attachProject(userId: number, projectId: number): Observable<User> {
    return this.http.post<User>(`${this.baseUrl}/${userId}/attachProject`, { projectId });
  }
  detachProject(userId: number, projectId: number): Observable<User> {
    return this.http.post<User>(`${this.baseUrl}/${userId}/detachProject`, { projectId });
  }
  // Delete
  deleteUserById(userId: number): Observable<any> {
    return this.http.delete<void>(`${this.baseUrl}/${userId}`);
  }
}
