import { inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  private renderer: Renderer2;
  activeTheme?: 'light' | 'dark';

  constructor() {
    this.renderer = inject(RendererFactory2).createRenderer(null, null);
    this.setTheme(this.detectPreferredColorScheme());
  }

  detectPreferredColorScheme(): 'light' | 'dark' {
    return window.matchMedia('(prefers-color-scheme: dark)').matches ? 'light' : 'dark';
  }

  setTheme(theme: 'light' | 'dark') {
    this.activeTheme = theme;

    this.renderer.removeClass(document.body, 'light');
    this.renderer.removeClass(document.body, 'dark');
    this.renderer.addClass(document.body, theme);
  }

  toggleTheme() {
    if (this.activeTheme === 'light') {
      this.setTheme('dark');
    } else {
      this.setTheme('light');
    }
  }

  getActiveTheme() {
    return this.activeTheme;
  }

  getCssVariable(variableName: string): string {
    return getComputedStyle(document.body)
      .getPropertyValue(variableName)
      .trim();
  }
}
