import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { SelectionModel } from '@angular/cdk/collections';
import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, QueryList, SimpleChanges, ViewChild, ViewChildren } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatRow, MatTableDataSource } from '@angular/material/table';
import { frenchRangeLabel } from 'src/app/models/mat-table.model';
import { Model } from 'src/app/models/model/model.model';
// import { relatedModels } from '../document-model-list/document-model-list.component';
import { firstValueFrom, Subject, takeUntil } from 'rxjs';
import { ModelCrudService } from 'src/app/services/crud/model/model-crud.service';
import { ImageCrudService } from 'src/app/services/crud/model/image-crud.service';
import { MatDialog } from '@angular/material/dialog';
import { ModelImage, ModelImages } from 'src/app/models/model/image.model';
import { DialogDocumentEditorData, DialogDocumentEditorDialogComponent } from 'src/app/shared/document-editor/dialog-document-editor-dialog/dialog-document-editor-dialog.component';
import { FULL_SCREEN_DIALOG_CONFIG } from 'src/app/models/dialog.model';

interface ModelRow {
  position: number,
  id: string;
  source: Model,
  name: string;
  type?: string;
  category?: string;
  language: string;
  page: string;
  image?: string;
  priority?: number,
  popularity?: number,
  selected?: boolean,

  loadingImage?: boolean;
  brokenImage?: boolean,

  createdAt: string,
  updatedAt: string
}

@Component({
  selector: 'app-document-models',
  templateUrl: './document-models.component.html',
  styleUrl: './document-models.component.scss'
})
export class DocumentModelsComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  @ViewChildren('imageCell') imageCells?: QueryList<ElementRef>;
  
  private destroy$ = new Subject<void>();
  
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  @Input() models: Model[] = [];
  @Input() selectedModels: Model[] = [];

  // This mode send data when selection in row is triggered
  // otherwise it needs a confirmation button before send
  @Input({transform: coerceBooleanProperty}) showImagesOnly: boolean = false;
  // Emit selection for each selection change without confirmation
  @Input({transform: coerceBooleanProperty}) dynamicSelection: boolean = false;
  @Input({transform: coerceBooleanProperty}) selectAllByDefault: boolean = false;
  @Input({transform: coerceBooleanProperty}) showTitle: boolean = false;
  @Input({transform: coerceBooleanProperty}) showSelection: boolean = false;
  @Input({transform: coerceBooleanProperty}) singleSelection: boolean = false;
  @Input({transform: coerceBooleanProperty}) removePaginator: boolean = false;
  @Input({transform: coerceBooleanProperty}) removeFilter: boolean = false;
  @Input({transform: coerceBooleanProperty}) removeShowableColumns: boolean = false;

  @Output() listChange = new EventEmitter<Model[]>();
  // Output some changes
  @Output() addedModels = new EventEmitter<Model[]>();
  @Output() removedModels = new EventEmitter<Model[]>();

  loading: boolean = false;

  displayedColumns: string[] = ['select', 'name', 'page', 'type', 'category', 'language', 'image'];
  dataSource = new MatTableDataSource<ModelRow>();
  selection = new SelectionModel<ModelRow>(true, []);

  observer?: IntersectionObserver;

  images: ModelImage[] = [];

  constructor(private modelApi: ModelCrudService,
    private imageApi: ImageCrudService,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.selection.changed.subscribe((selection) => {
      if (selection.added.length > 0) {
        const addedModels = selection.added.map(modelRow => modelRow.source);
        this.addedModels.emit(addedModels);
      }
      if (selection.removed.length > 0) {
        const removedModels = selection.removed.map(modelRow => modelRow.source);
        this.removedModels.emit(removedModels);
      }
    });
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (changes['models']) {
      await this.loadModels();
      this.selectModels();

      if (this.selectAllByDefault) {
        // Select all by default
        this.selection.clear();
        this.toggleAllRows();
      }
      this.observeVisibleRows();
    }

    if (changes['selectedModels'] && this.selectedModels?.length > 0) {
      this.selectModels();
    }

    if (changes['showImagesOnly']) {
      if (this.showImagesOnly) {
        this.displayedColumns = ['select', 'image'];
      }
    }

    if (changes['singleSelection']) {
      if (this.singleSelection) {
        this.displayedColumns = this.displayedColumns.filter(col => col !== 'select') ;
      }
    }
  }

  ngAfterViewInit() {
    if (!this.removePaginator) {
      this.paginator._intl.itemsPerPageLabel = $localize `Models per page:`;
      this.paginator._intl.getRangeLabel = frenchRangeLabel;

      this.dataSource.paginator = this.paginator;
    }
    
    this.dataSource.sort = this.sort;

    this.observeVisibleRows();
  }

  // Function to lazy load images from rows
  observeVisibleRows(): void {
    // SetTimeOut to let the dom loaded
    setTimeout(() => {
      if (this.observer) {
        this.observer.disconnect();
      }
  
      this.observer = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            const modelId = entry.target.getAttribute('model-id') ?? undefined;
            if (modelId) {
              const modelRow = this.dataSource.data.find(data => data.id === modelId);
              if (modelRow)
              if (!modelRow.image && !modelRow.loadingImage) {
                this.loadImage(modelRow);
              }
            }
          }
        });
      });
      // Each visible rows should be observed
      this.imageCells?.forEach(cell => {
        this.observer?.observe(cell.nativeElement as Element);
      });
    }, 0);
  }

  async loadModels() {
    this.dataSource.data = this.convertToRows(this.models);
  }

  selectModels() {
      const selectedModelIds: string[] = this.selectedModels.map(model => model.id) || [];
      for (const modelRow of this.dataSource.data) {
        if (selectedModelIds.includes(modelRow.id)) {
          this.selection.select(modelRow);
        } else {
          this.selection.deselect(modelRow);
        }
      }
  }

  convertToRows(models: Model[]): ModelRow[] {
    const modelRows: ModelRow[] = [];
    models.forEach((model: Model, modelIdx) => {
       const newModelRow: ModelRow = {
        position: modelIdx,
        id: model.id.toString(),
        source: model,
        name: model.name?.toString(),
        page: model.page?.toString(),
        type: model.docType?.name,
        category: model.docType?.category?.name,
        language: model.language!.code,
        // Image will load next time
        loadingImage: false,
        brokenImage: false,
        createdAt: model.createdAt!,
        updatedAt: model.updatedAt!,
        popularity: model.popularity ?? undefined,
       };
       modelRows.push(newModelRow);
    });

    return modelRows;
  }

  async loadImages(modelRows: ModelRow[]) {
    modelRows.forEach(async row => {
      await this.loadImage(row);
    });
  }

  async loadImage(row: ModelRow) {
    try {
      const modelImages: ModelImages = await firstValueFrom(this.modelApi.getModelImagesById(row.id));
      const previewImage = modelImages.images.find(image => image.type === 'preview');
      if (previewImage) {
        this.images.push(previewImage);
        row.loadingImage = true;
        const imageString = await this.getImage(previewImage.id);
        row.image = imageString;
      }
    } catch (error) {
      row.brokenImage = true;
    } finally {
      row.loadingImage = false;
    }
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.dataSource.data);

    if (this.dynamicSelection) {
      this.sendModels();
    }
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: ModelRow): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;

    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator && !this.removePaginator) {
      this.dataSource.paginator.firstPage();
    }

    this.observeVisibleRows();
  }

  sendModels() {
    const selectedModelIds: string[] = this.selection.selected.map(row => row.id);
    const modelsToSend: Model[] = this.models.filter(model => selectedModelIds.includes(model.id));
    this.listChange.emit(modelsToSend);
  }

  toggle(row: any, withoutEmit?: boolean) {
    if (this.singleSelection) {
      this.selection.clear();
    }
    this.selection.toggle(row);
    // ? Maybe pick this in selection subscribe
    if (this.dynamicSelection && !withoutEmit) {
      this.sendModels();
    }
  }

  showImage(modelRow: ModelRow) {
    this.dialog.open(DialogDocumentEditorDialogComponent, {data: <DialogDocumentEditorData>{model: modelRow.source, preview: true},  ...FULL_SCREEN_DIALOG_CONFIG});
  }

  async getImage(imageId: string) {
    let result = '';
    try {
        result = await firstValueFrom(this.imageApi.getImageById(imageId));
    } catch (error) {
      throw error
    }
    return result;
  }

  toggleColumn(colName: string) {
    // We let image at the end of the table
    const imageIndex = this.displayedColumns.indexOf('image');
    if (imageIndex > -1) {
      this.displayedColumns.splice(imageIndex, 1);
    }
    const foundColId = this.displayedColumns.indexOf(colName);
    if (foundColId > -1) {
      this.displayedColumns.splice(foundColId, 1);
    } else {
      this.displayedColumns.push(colName);
    }
    this.displayedColumns.push('image');
    this.observeVisibleRows();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    this.observer?.disconnect();
  }
}
