import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EnvironmentService } from '../../environment.service';
import { DocType } from 'src/app/models/model/model.model';

@Injectable({
  providedIn: 'root'
})
export class DocTypeModelCrudService {
  private baseUrl = `${this.environment.apiUrl}/list/docType`;

  constructor(private http: HttpClient, private environment: EnvironmentService) { }

  // Get
  getdocTypes(): Observable<DocType[]> {
    return this.http.get<DocType[]>(this.baseUrl);
  }
}
