import { Injectable } from '@angular/core';
import { TreeNode, UrlTreeNode } from '../models/tree-node.model';
import { NestedTreeControl } from '@angular/cdk/tree';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { Customer } from '../models/customer.model';
import { StageCrudService } from './crud/stage-crud.service';
import { CustomerCrudService } from './crud/customer-crud.service';
import { BehaviorSubject, firstValueFrom, interval } from 'rxjs';
import { Stage } from '../models/stage.model';
import { NODES, NodeType, getNodeIcon } from '../models/global.model';
import { LockService } from './lock.service';
import { LockData } from '../models/socket.model';

@Injectable({
  providedIn: 'root',
})

/**
 * Useful for retrieving data from route (tree node id)
 */
export class TreeService {
  refreshIntervalMs = 120 * 1000; // 2 minutes

  // Inform all components to the new data nodes after refresh
  private treeStateSubject = new BehaviorSubject<TreeNode[]>([]);
  treeData$ = this.treeStateSubject.asObservable();

  // Inform all components that there is a loading concerning tree nodes
  private loadingTreeSubject = new BehaviorSubject<boolean>(false);
  loadingTree$ = this.loadingTreeSubject.asObservable();

  public treeControl = new NestedTreeControl<TreeNode>(node => node.children);
  public dataSource = new MatTreeNestedDataSource<TreeNode>();
  public expandedNodes: TreeNode[] = []

  public get nestedTree() {
    return this.dataSource.data;
  }

  public async fetchTree(params?: {hideLoadingState?: boolean}) {
    if (!params?.hideLoadingState) {
      this.loadingTreeSubject.next(true);
    }
    await this.generateTree();
    this.refreshTree();
    if (!params?.hideLoadingState) {
      this.loadingTreeSubject.next(false);
    }
  }

  public refreshTree() {
    this.treeStateSubject.next(this.dataSource.data);
  }

  /**
   * Get all nodes from tree with array disposition with hierarchy.
   */
  public get flatNestedTree() {
    let result: TreeNode[] = [];
    for (const customerNode of this.dataSource.data) {
      result.push(customerNode);
      for (const projectNode of customerNode.children) {
        result.push(projectNode);
        for (const configNode of projectNode.children) {
          result.push(configNode);
        }
      }
    }
    return result;
  }

  constructor(
    private customerApi: CustomerCrudService,
    private stageApi: StageCrudService,
    private lockService: LockService) 
  {
    // Refresh manually all tree (2min between each refresh)
    this.startAutoRefresh();
    // Listen modifications concerning locked configs and update tree automatically
    this.autoUpdateLockConfigState();
  }

  private autoUpdateLockConfigState() {
    this.lockService.lockedConfig$.subscribe((data: LockData | undefined) => {
      if (data) {
        this.editNode(data.configId, 'config', 'locked', data.locked);
      }
    });
  }

  private async startAutoRefresh() {
    interval(this.refreshIntervalMs).subscribe(async () => {
      await this.fetchTree({hideLoadingState: true});
    });
  }

  getNodeById(nodeType: NodeType, nodeId: number): TreeNode | undefined {
    return this.flatNestedTree.find(node => node.type === nodeType && node.id === nodeId);
  }

  /**
   * Find the parent from the reference node in input.
   * @param baseNode - Node we refer to find the parent node.
   * @returns The parent node.
   */
  findParentNode(refNode: TreeNode) {
    return this.flatNestedTree.find(node => node.type === refNode.parentType && node.id === refNode.parentId);
  }

  /**
   * Convert each node's group to sorted tree node.
   * @param groups
   * @returns Complete tree to show - Adapted for nested-data-tree.
   */
  async generateTree() {
    let treeNodes: TreeNode[] = [];
    const userCustomers: Customer[] = await firstValueFrom(this.customerApi.getCustomers());
    if (userCustomers.length > 0) {
      let stages: Stage[] = await firstValueFrom(this.stageApi.getStages());
      // Generation with creation loops
      for (const customer of userCustomers) {
        const customerNode: TreeNode = {
          id: customer.id,
          level: 1,
          name: customer.name,
          type: NODES.CUSTOMER,
          children: [],
          icon: getNodeIcon(NODES.CUSTOMER),
        };

        if (customer.projects && customer.projects.length > 0) {
          for (const project of customer.projects) {
            const projectNode: TreeNode = {
              id: project.id,
              level: 2,
              name: project.name,
              type: NODES.PROJECT,
              icon: getNodeIcon(NODES.PROJECT),
              children: [],
              parentType: NODES.CUSTOMER,
              parentId: customer.id,
            };
            customerNode.children.push(projectNode);

            if (project.configs && project.configs.length > 0) {
              for (const config of project.configs) {
                const stage = stages.find(stage => stage.id === config.stageId);
                const configNode: TreeNode = {
                  id: config.id,
                  level: 3,
                  name: config.name,
                  type: NODES.CONFIG,
                  children: [],
                  stage,
                  icon: getNodeIcon(NODES.CONFIG),
                  parentType: NODES.PROJECT,
                  parentId: project.id,
                  locked: config.locked
                };
                projectNode.children!.push(configNode);
              }
            }
            // Sort configs by project
            projectNode.children!.sort((a, b) => a.name.localeCompare(b.name));
          }
        }
        // Sort projects by customer
        customerNode.children!.sort((a, b) => a.name.localeCompare(b.name));
        treeNodes.push(customerNode);
      }
      // Sort customers
      this.dataSource.data = treeNodes.sort((a, b) => a.name.localeCompare(b.name));
    }
    this.treeControl.dataNodes = this.dataSource.data;
    this.expandNodesFromHierarchy();

    return treeNodes || [];
  }

  // When we refresh tree, we have to expand again some nodes to retrieve the same visual compared to earlier
  expandNodesFromHierarchy(): void {
    this.dataSource.data.forEach((customerNode, customerIdx) => {
      if (this.findExpandedNode(customerNode.id, customerNode.type)) {
        this.treeControl.expand(this.dataSource.data[customerIdx]);
      }
      customerNode.children.forEach((projectNode, projectIdx) => {
        if (this.findExpandedNode(projectNode.id, projectNode.type)) {
          this.treeControl.expand(this.dataSource.data[customerIdx].children[projectIdx]);
        }
        projectNode.children.forEach((configNode, configIdx) => {
          if (this.findExpandedNode(configNode.id, configNode.type)) {
            this.treeControl.expand(this.dataSource.data[customerIdx].children[projectIdx].children[configIdx]);
          }
        });
      });
    });
  }

  findExpandedNode(id: number, type: NodeType) {
    return this.expandedNodes.find(expNode => expNode.id === id && expNode.type === type);
  }

  findIndexExpandedNode(id: number, type: NodeType) {
    return this.expandedNodes.findIndex(expNode => expNode.id === id && expNode.type === type);
  }

  editNode(nodeId: number, nodeType: NodeType, key: string | number, newValue: any) {
    this.updateNestedProperty(this.dataSource.data, nodeId, nodeType, key, newValue);
    this.refreshTree();
  }

  updateNestedProperty(data: any, id: any, type: any, key: string | number, value: any) {
    for (let item of data) {
      if (item.id === id && item.type === type) {
        item[key] = value;
        // Edition done
        return true;
      }
      if (item.children) {
        let found = this.updateNestedProperty(item.children, id, type, key, value);
        if (found) {
          // Edition done in children
          return true;
        }
      }
    }
    // Id not found
    return false;
  }

  /**
   * Expand the tree to retrieve visually the selected node.
   * @param node 
   */
  expandNodeHierarchy(node: TreeNode) {
    if (node.type === 'project') {
      const customerNode = this.findParentNode(node)!;
      this.treeControl.expand(customerNode);
    }
    if (node.type === 'config') {
      const projectNode = this.findParentNode(node)!;
      const customerNode = this.findParentNode(projectNode)!;
      this.treeControl.expand(customerNode);
      this.treeControl.expand(projectNode);
    }
  }

  /**
   * Make an url tree (I could make a recursive function...).
   * @param node 
   * @returns Array with url tree options (name and navigation info).
   */
  getUrlTreeFromNodeId(node: TreeNode): UrlTreeNode[] {
    let result: UrlTreeNode[] = [];
    if (node.type === 'config') {
      const projectNode = this.findParentNode(node)!;
      const customerNode = this.findParentNode(projectNode)!;
      result.push(
        { id: customerNode.id, name: customerNode.name, type: customerNode.type, redirect: [`/${customerNode.type}`, customerNode.id] },
        { id: projectNode.id, name: projectNode.name, type: projectNode.type, redirect: [`/${projectNode.type}`, projectNode.id] },
        { id: node.id, name: node.name, type: node.type, redirect: [`/${node.type}`, node.id], stage: node.stage, locked: node.locked, onHere: true });
    }
    if (node.type === 'project') {
      const customerNode = this.findParentNode(node)!;
      result.push(
        { id: customerNode.id, name: customerNode.name, type: customerNode.type, redirect: [`/${customerNode.type}`, customerNode.id] },
        { id: node.id, name: node.name, type: node.type, redirect: [`/${node.type}`, node.id], onHere: true });
    }
    if (node.type === 'customer') {
      result.push({ id: node.id, name: node.name, type: node.type, redirect: [`/${node.type}`, node.id], onHere: true });
    }
    return result;
  }
}
