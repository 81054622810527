<mat-accordion>
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title i18n>
				<mat-icon>picture_in_picture_alt</mat-icon>
				Selection properties 
			</mat-panel-title>
    </mat-expansion-panel-header>

		<form [formGroup]="boxForm">
			<span class="mat-title-small">Attributes</span>
			<mat-form-field appearance="outline">
				<input matInput formControlName="name"/>
				<span matTextPrefix>A&nbsp;</span>
			</mat-form-field>

			<mat-form-field appearance="outline">
				<mat-icon matPrefix>wallpaper</mat-icon>
				<mat-select formControlName="type">
				@for (key of boxKeys; track $index) {
					<mat-option [value]="key">
						<button mat-stroked-button [ngStyle]="getBoxStyles(key)" class="box-button">{{getBoxDescription(key)}}</button>
					</mat-option>
				} 
				</mat-select>
			</mat-form-field>

			<!-- <mat-slide-toggle i18n>Optional</mat-slide-toggle> -->

			<mat-divider></mat-divider>

			<span class="mat-title-small">Position & size</span>
			<div class="measures">
				<mat-form-field appearance="outline">
					<input matInput type="number" formControlName="x"/>
					<span matTextPrefix>X&nbsp;</span>
				</mat-form-field>

				<mat-form-field appearance="outline">
					<input matInput type="number" formControlName="y"/>
					<span matTextPrefix>Y&nbsp;</span>
				</mat-form-field>

				<mat-form-field appearance="outline">
					<input matInput type="number" formControlName="width"/>
					<span matTextPrefix>W&nbsp;</span>
				</mat-form-field>

				<mat-form-field appearance="outline">
					<input matInput type="number" formControlName="height"/>
					<span matTextPrefix>H&nbsp;</span>
				</mat-form-field>
			</div>

			<mat-divider></mat-divider>
		</form>

		<span class="mat-title-small">Actions</span>

		<div class="actions">
			<button mat-button (click)="removeBox()">
				<mat-icon>delete</mat-icon>
				Remove
			</button>
		</div>

  </mat-expansion-panel>
</mat-accordion>
