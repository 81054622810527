import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RadialConfig } from 'src/app/models/radial.model';
import { EnvironmentService } from '../environment.service';

@Injectable({
    providedIn: 'root'
  })
  export class RadialConfigCrudService {
    private baseUrl = `${this.environment.apiUrl}/deploy/radial`;

    constructor(private http: HttpClient, private environment: EnvironmentService) { }
  
      // Get
    getConfigs(): Observable<RadialConfig[]> {
      return this.http.get<RadialConfig[]>(this.baseUrl);
    }
    getConfigByName(name: string): Observable<any> {
      return this.http.get<any>(`${this.baseUrl}/${name}`);
    }
    // Post
    deployConfig(name: string): Observable<any> {
      return this.http.post(this.baseUrl, { name }, { responseType: 'text' });
    }

    // PUT
    updateConfig(config: any): Observable<any> {
      return this.http.put<any>(this.baseUrl, config);
    }

    // Delete
    deleteConfig(name: string): Observable<any> {
      return this.http.delete<any>(`${this.baseUrl}/${name}`);
    }
}