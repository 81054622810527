import { Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { firstValueFrom } from 'rxjs';
import { DialogModelsData, DialogModelsDialogComponent, DialogModelsOutput } from 'src/app/home/documents/dialog-models-dialog/dialog-models-dialog.component';
import { BoxType, Model, RawBox } from 'src/app/models/model/model.model';
import { KonvaService } from 'src/app/services/konva/konva.service';
import { ModelCrudService } from 'src/app/services/crud/model/model-crud.service';
import { ImageCrudService } from 'src/app/services/crud/model/image-crud.service';
import { FULL_SCREEN_DIALOG_CONFIG } from 'src/app/models/dialog.model';
import { ModelImages } from 'src/app/models/model/image.model';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { EditorStateService } from 'src/app/services/konva/editor-state.service';
import { ComputerModelService } from 'src/app/services/crud/model/computer.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DialogDocumentCreatorDialogComponent } from './dialog-document-creator-dialog/dialog-document-creator-dialog.component';
import { KonvaEventService } from 'src/app/services/konva/konva-event.service';
import { KonvaHistoryService } from 'src/app/services/konva/konva-history.service';
import { KonvaMenuService } from 'src/app/services/konva/konva-menu.service';
import { KonvaRenameBoxService } from 'src/app/services/konva/konva-rename-box.service';
import { KonvaSnappingService } from 'src/app/services/konva/konva-snapping.service';

@Component({
  selector: 'app-document-editor',
  templateUrl: './document-editor.component.html',
  styleUrl: './document-editor.component.scss',
  providers: [
    EditorStateService,
    KonvaService, 
    KonvaEventService, 
    KonvaHistoryService, 
    KonvaMenuService, 
    KonvaRenameBoxService, 
    KonvaSnappingService]
})
export class DocumentEditorComponent implements OnInit, OnChanges {
  @ViewChild('menu') menu!: ElementRef<HTMLDivElement>;

  @Input() document?: Model;
  @Input({transform: coerceBooleanProperty}) previewMode: boolean = false;
  @Input({transform: coerceBooleanProperty}) readonlyMode: boolean = false;

  documentBeforeEdition?: Model

  selectedBoxType?: BoxType;
  
  imageUrl: string = '';

  selectedBox?: RawBox;

  showBoxes: boolean = true;

  constructor(
    public konvaService: KonvaService, 
    private matDialog: MatDialog,
    private editorState: EditorStateService,
    private modelApi: ModelCrudService,
    private computerApi: ComputerModelService,
    private snackbar: MatSnackBar,
    private imageApi: ImageCrudService) 
  {}

  async ngOnChanges(changes: SimpleChanges) {
    if (changes['document'] && this.document) {
      await this.fetch(this.document.id);
    }

    if (changes['previewMode'] && this.previewMode) {
      this.readonlyMode = true;
    }
  }

  async ngOnInit() {
    if (!this.previewMode) {
      await this.searchModel();
    }
    // Select
    this.editorState.selectedBoxId$.subscribe((key: number | undefined) => {
      const model = this.editorState.getModel();
      if (model) {
        this.selectedBox = model.rawBoxes.find(box => box.key === key);
      }
    });
    // Update
    this.editorState.updatedBox$.subscribe((box: RawBox | undefined) => {
      const selectedId = this.editorState.getSelectedBoxId();
      if (selectedId === box?.key) {
        this.selectedBox = box;
      }
    });
  }

  async searchModel() {
    const dialogModelData: DialogModelsData = {
      sortableList: true,
      singleSelection: true,
      title: $localize `Search model`,
      createModelOption: true,
    }
    const dialogRef = this.matDialog.open(DialogModelsDialogComponent, { 
      data: dialogModelData, ...FULL_SCREEN_DIALOG_CONFIG
     });
    dialogRef.afterClosed().subscribe(async (output: DialogModelsOutput) => {
      if (output) {
        // Get Model from selection
        if (output.models && output.models[0]) {
          await this.fetch(output.models[0].id);
        }
        // Or create model
        if (output.createModel) {
          this.openModelCreation();
        }
      }
    });
  }

  async fetch(modelId: string) {
    this.document = await firstValueFrom(this.modelApi.getModelById(modelId));
    this.editorState.modelSubject.next(this.document);
    this.documentBeforeEdition = this.document;
    await this.loadImage(modelId);
    this.konvaService.initStage('scene', this.menu, { model: this.document, image: this.imageUrl });
  }

  async loadImage(modelId: string) {
    const modelImages: ModelImages = await firstValueFrom(this.modelApi.getModelImagesById(modelId));
    const mainImageId = modelImages.images.find(image => image.type === 'main');
    if (mainImageId) {
      this.imageUrl = await firstValueFrom(this.imageApi.getImageById(mainImageId.id));
    }
  }

  onSelectChange(event: any) {
    this.selectedBoxType = event.value;
    this.konvaService.createBox(this.selectedBoxType!);
  }

  // Méthode appelée lorsque l'utilisateur clique sur la scène pour ajouter une boîte
  onStageClick(event: MouseEvent): void {
    // if (this.selectedBoxType) {
    //   this.konvaService.addBox(event.offsetX, event.offsetY, this.selectedBoxType);
    // }
  }

  emitUpdatedBox(box: RawBox) {
    this.editorState.updateBoxSubject.next(box);
  }

  async save() {
    let model: Model | undefined = this.editorState.getModel();
    if (model) {
      model = {...model, configIds: model?.configs?.map(config => config.id)}
      try {
        this.snackbar.open('Saving...');
        // TODO
        // await firstValueFrom(this.modelApi.(model));
      } catch (error) {
        this.snackbar.open((error as Error).message);
      } finally {
        this.snackbar.open('Save finished', 'Ok', { duration: 3000 });
      }
    }
  }

  async compute() {
    let model: Model | undefined = this.editorState.getModel();
    if (model) {
      model = {...model, configIds: model?.configs?.map(config => config.id)}
      try {
        this.snackbar.open('Computing...');
        await firstValueFrom(this.computerApi.computeImage(model));
      } catch (error) {
        this.snackbar.open((error as Error).message);
      } finally {
        this.snackbar.open('Compute finished', 'Ok', { duration: 3000 });
      }
    }
  }

  async openModelCreation() {
    const dialogRef = this.matDialog.open(DialogDocumentCreatorDialogComponent, { minWidth: '750px' });
    dialogRef.afterClosed().subscribe(async (modelId?: string) => {
      if (modelId) {
        await this.fetch(modelId);
      } else {
        await this.searchModel();
      }
    });
  }

  createBox(type: any) {
    this.editorState.emitCreationBox(type);
  }

  updateDocument(model: Model) {
    if (this.document) {
      Object.assign(this.document, model);
    }
  }

  removeBox(id: number) {
    this.editorState.deleteBox(id);
  }

  toggleBoxes() {
    if (this.showBoxes) {
      this.konvaService.hideAllBoxes();
    } else {
      this.konvaService.showAllBoxes();
    }
    this.showBoxes = !this.showBoxes;
  }
}
