import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { getNodeIcon } from '../../../../models/global.model';
import { Stage } from 'src/app/models/stage.model';
import { ConfigService } from 'src/app/services/config/config.service';

export interface DialogSaveConfigData {
  stage?: Stage;
  configName: string;

  refreshAfterSaved: boolean;
  skipConfirmation: boolean;

  // * Show before living an editing configuration without save
  warnBeforeLeaving?: boolean;
}

export type DialogSaveConfigCloseState = 'save' | 'discard' | 'cancel';

@Component({
  selector: 'app-dialog-save-config-dialog',
  templateUrl: './dialog-save-config-dialog.component.html',
  styleUrl: './dialog-save-config-dialog.component.scss'
})
export class DialogSaveConfigDialogComponent implements OnInit {
  icon: string = '';
  saving: boolean = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogSaveConfigData,
  private matDialog: MatDialogRef<DialogSaveConfigDialogComponent>,
  private configService: ConfigService,
  private snackbar: MatSnackBar)
  {
    this.icon = getNodeIcon('config');
  }

  async ngOnInit() {
    if (this.data.skipConfirmation) {
      await this.save();
    }
  }

  async save() {
    try {
      this.saving = true;
      // Save can only be triggered when we are in config and using config service so...
      await this.configService.save({refreshAfterSaved: this.data.refreshAfterSaved});
      this.snackbar.open(`Sauvegarde de ${this.data.configName} en ${this.data.stage ? this.data.stage.name : 'N/A'} effectuée.`, 'Ok');
    } catch (error) {
      this.snackbar.open((error as Error).message);
    } finally {
      this.saving = false;
      this.matDialog.close(true);
    }
  }
}
