import { Injectable } from '@angular/core';
import { UserService } from './user.service';
import { KeycloakService } from 'keycloak-angular';
import { KeycloakProfile } from 'keycloak-js';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public error: boolean = false;

  public isLogging = false;
  public isLoggedIn = false;
  public userProfile: KeycloakProfile | null = null;
  public bearerToken: string = '';

  constructor(
    private readonly keycloak: KeycloakService,
    private userService: UserService,
    private snackbar: MatSnackBar
  ) {}

  async login() {
    this.isLogging = true;
    this.error = false;
    this.isLoggedIn = this.keycloak.isLoggedIn();

    try {
      if (this.isLoggedIn) {
        this.userProfile = await this.keycloak.loadUserProfile();

        if (this.userProfile.email) {
          this.bearerToken = await this.keycloak.getToken();
          // Define new user
          await this.userService.refreshUser(this.userProfile.email);
          this.snackbar.open($localize`Welcome !`, $localize`Thanks`, {
            duration: 4000,
          });
        }
      } else {
        await this.keycloak.login({ idpHint: 'default' });
      }
    } catch (error) {
      this.error = true;
      this.snackbar.open((error as Error).message, 'Ok', {duration: 100000});
      throw error;
    } finally {
      this.isLogging = false;
    }
  }

  public logout() {
    this.keycloak.logout();
    this.isLoggedIn = false;
  }
}
