<mat-chip-listbox aria-label="Filters fields">
	<mat-chip-option 
		[selected]="sortName === 'Category'"
		(click)="sortedList = groupItemsByKey('Category', 'category'); selectModels()">
		Sort by category
	</mat-chip-option>
	<mat-chip-option 
		[selected]="sortName === 'Language'"
		(click)="sortedList = groupItemsByKey('Language', 'language'); selectModels()">
		Sort by language
	</mat-chip-option>
	<mat-chip-option 
		[selected]="sortName === 'Selected'"
		(click)="sortedList = groupItemsBySelection('Selected')">
		Sort by selection
	</mat-chip-option>
</mat-chip-listbox>

<div class="list">
	@for (group of sortedList | keyvalue; track group.key) {
		<div class="list-header">
				<mat-checkbox #selection [checked]="allChecked(group.key)" (change)="toggleCheckAll(group.key, selection.checked)"></mat-checkbox>
				<mat-icon class="toggle small" (click)="toggleGroup(group.key); $event.stopPropagation()">
					{{ hiddenGroups.includes(group.key) ? 'keyboard_arrow_down' : 'keyboard_arrow_up' }}
				</mat-icon>
				<span class="title">
					{{ group.key }}
				</span>
		</div>

		@if (!hiddenGroups.includes(group.key)) {
			@for (row of sortedList[group.key]; track $index) {
				<div class="list-item" (click)="toggleCheckRow(row); $event.stopPropagation()">
					<mat-checkbox [checked]="row.selected">
						<span [matBadge]="row.selectedModels" [matBadgeHidden]="row.selectedModels === 0" matBadgeOverlap="false">
							{{ row.type }} 
							@if (showModels) {
								({{ row.models.length }})
							}
						</span>
					</mat-checkbox>
				</div>
			}
		}
	}
</div>

