import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

export interface DialogDocumentPostCreationOutputData {
  edit?: boolean;
  continueToCreate: boolean;
  findAnotherModel: boolean;
}

@Component({
  selector: 'app-dialog-document-post-creation-dialog',
  templateUrl: './dialog-document-post-creation-dialog.component.html',
  styleUrl: './dialog-document-post-creation-dialog.component.scss'
})
export class DialogDocumentPostCreationDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
  private matDialog: MatDialogRef<DialogDocumentPostCreationDialogComponent>)
  {}

  findModel() {
    this.matDialog.close(<DialogDocumentPostCreationOutputData>{findAnotherModel: true});
  }

  continueCreation() {
    this.matDialog.close(<DialogDocumentPostCreationOutputData>{continueToCreate: true});
  }

  editModel() {
    this.matDialog.close(<DialogDocumentPostCreationOutputData>{edit: true});
  }
}
