import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { firstValueFrom } from 'rxjs';
import { DialogDeleteConfigDialogComponent } from '../dialog-delete-config-dialog/dialog-delete-config-dialog.component';
import { ProjectCrudService } from 'src/app/services/crud/project-crud.service';
import { MatSnackBar } from '@angular/material/snack-bar';

export interface DialogDeleteProjectData {
  nodeName: string;
  nodeId: number;
  nodeParentName: string;
}

@Component({
  selector: 'app-dialog-delete-project-dialog',
  templateUrl: './dialog-delete-project-dialog.component.html',
  styleUrl: './dialog-delete-project-dialog.component.scss'
})
export class DialogDeleteProjectDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogDeleteProjectData,
  private matDialog: MatDialogRef<DialogDeleteConfigDialogComponent>,
  private projectApi: ProjectCrudService,
  private snackbar: MatSnackBar)
  {}

  async confirm() {
    try {
      const deletedProject = await firstValueFrom(this.projectApi.deleteProject(this.data.nodeId));
      this.snackbar.open(`Projet ${this.data.nodeName} supprimée.`, 'Ok', { duration: 3000 });
      this.matDialog.close(deletedProject);
    } catch (error) {
      this.snackbar.open((error as Error).message);
    }
  }

}
