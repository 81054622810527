import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { NODE_TYPES } from 'src/app/models/global.model';
import { AuthService } from 'src/app/services/auth.service';
import { DrawerService } from 'src/app/services/drawer.service';
import { ThemeService } from 'src/app/services/theme.service';
import { UserService } from 'src/app/services/user.service';
import { DialogMigrationLoaderDialogComponent } from './dialog-migration-loader-dialog/dialog-migration-loader-dialog.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  openedDrawer: boolean = true;
  theme?: 'light' | 'dark';

  constructor(
    private authService: AuthService, 
    public router: Router,
    public userService: UserService, 
    private drawerService: DrawerService,
    public themeService: ThemeService,
    public snackar: MatSnackBar,
    private dialog: MatDialog) {
  }

  get user() {
    return this.userService.user;
  }

  ngOnInit() {
    this.drawerService.getProjectsDrawerState().subscribe((isOpen: boolean) => {
      this.openedDrawer = isOpen;
    });
    this.theme = this.themeService.getActiveTheme()
  }

  logout() {
    this.authService.logout();
  }

  toggleDrawer() {
    this.drawerService.toggleProjectsDrawer(!this.openedDrawer);
  }

  toggleTheme() {
    this.themeService.toggleTheme();
    this.theme = this.themeService.getActiveTheme();
  }

  isInProjects() {
    // ! Add here some routes to keep the toggle button to show tree
    const treeRoutes: string[] = ['home', ...NODE_TYPES];
    return treeRoutes.some(route => this.router.url.includes(route));
  }

  openMigrationDialog() {
    this.dialog.open(DialogMigrationLoaderDialogComponent, {disableClose: true});
  }
}
