import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { SocketService } from './socket.service';
import { LockData } from '../models/socket.model';

@Injectable({
  providedIn: 'root'
})
export class LockService {
  private lockedConfigSubject: BehaviorSubject<LockData | undefined> = new BehaviorSubject<LockData | undefined>(undefined);
  lockedConfig$ = this.lockedConfigSubject.asObservable();

  constructor(private socketService: SocketService) {
    this.socketService.socket.on('infoConfig', (data: { configId: number, locked: boolean }) => {
      this.lockedConfigSubject.next(data);
    });
  }

  lockConfig(jwt: any, configId: any) {
    this.socketService.socket?.emit('lockConfig', {
      jwt: jwt,
      configId: configId
    })
  }

  unlockConfig(jwt: any, configId: any) {
    this.socketService.socket?.emit('unlockConfig', {
      jwt: jwt,
      configId: configId
    })
  }
}
