import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EnvironmentService } from '../../environment.service';
import { Model } from 'src/app/models/model/model.model';

@Injectable({
  providedIn: 'root'
})
export class ComputerModelService {
  private baseUrl = `${this.environment.apiUrl}/computer`;

  constructor(private http: HttpClient, private environment: EnvironmentService) {}

  computeImage(model: Model): Observable<any> {
    return this.http.post(`${this.baseUrl}/computeImage`, model);
  }

	computeText(model: Model): Observable<any> {
    return this.http.post(`${this.baseUrl}/computeText`, model);
  }

	// register(model: Model): Observable<any> {
  //   return this.http.post(`${this.baseUrl}/register`, model);
  // }
}
