import { Injectable } from '@angular/core';
import { SchemaKeys, SchemaNode, TYPE_TRANSFORMER } from '../../models/schema/schema.model';
import { Model } from '../../models/model/model.model';
import { Config, SchemaWithModels } from '../../models/config.model';
import { BehaviorSubject, firstValueFrom, map, Subject } from 'rxjs';
import { ConfigCrudService } from '../crud/config-crud.service';
import { ModelField } from '../../models/model/field.model';

import { Schema, SchemaFile, SchemaDocument, SchemaPage, SchemaField, SchemaJtd, JTD_SCHEMA, DocumentConstraintRegistor, FileConstraintRegistor } from '@doclab/schema';

import { ConfigModelService, LinkedModel } from './config-model.service';
import { SchemaCrudService } from '../crud/schema/schema-crud.service';
import { SchemaSelectionService } from './schema-selection.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserService } from '../user.service';
import { LockService } from '../lock.service';
import { LockData } from 'src/app/models/socket.model';
import { ConfigConstraintsService } from './config-constraints.service';
import { RadialConfigCrudService } from '../crud/radial-crud.service';

@Injectable({
	providedIn: 'root'
})
export class ConfigService {
	private refreshedDataSubject: Subject<SchemaWithModels> = new Subject<SchemaWithModels>();
	refreshedData$ = this.refreshedDataSubject.asObservable();

	public selectedModelsSubject: BehaviorSubject<Model[]> = new BehaviorSubject<Model[]>([]);
	selectedModels$ = this.selectedModelsSubject.asObservable();

	private fetchingConfigSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
	fetchingConfig$ = this.fetchingConfigSubject.asObservable();

	private loadingConfigSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
	loadingConfig$ = this.loadingConfigSubject.asObservable();

	private loadingFieldsSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
	loadingFields$ = this.loadingFieldsSubject.asObservable();

	private lockedConfigSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
	lockedConfig$ = this.lockedConfigSubject.asObservable().pipe(map((locked) => {
		// We want a config with a userId who locks different than our curr user id to interprete it's locked
		if (locked && this.config && this.config.lockedByUserId &&
				this.config.lockedByUserId !== this.userService.user?.id) {
			return true;
		}
		return false;
	}));

	config!: Config;
	configModified: boolean = false;

	editedSchemaId: string = '';
	editedSchema: Schema = new Schema();

	constructor(private configApi: ConfigCrudService,
		private configModelService: ConfigModelService,
		private schemaSelectionService: SchemaSelectionService,
		private radialConfigApi: RadialConfigCrudService,
		private schemaApi: SchemaCrudService,
		private snackbar: MatSnackBar,
		private lockService: LockService,
		private userService: UserService,
		private configConstr: ConfigConstraintsService
	) {
		this.lockService.lockedConfig$.subscribe((data: LockData | undefined) => {
			if (data && this.config) {
				if (data.configId === this.config?.id) {
					this.lockedConfigSubject.next(data.locked);
				}
			}
		})
	}

	discardChanges(configId: number) {
		this.fetchConfig(configId);
	}

	async buildConfig(): Promise<boolean> {
		let built: boolean = false;
		const schemaObject = JSON.parse(JSON.stringify(this.editedSchema));
		const schemaTester = new SchemaJtd(JTD_SCHEMA).validateJtd(schemaObject);
		if (schemaTester.isOk()) {
			this.config.schema = { ...this.config.schema, body: JSON.stringify(this.editedSchema) };
			this.config.schemaId = this.editedSchemaId;
			await firstValueFrom(this.schemaApi.editSchema(this.editedSchemaId, this.config.schema));

			this.config.models = this.configModelService.configModels.map(lModel => lModel.model);
			built = true;
		} else {
			throw schemaTester.error;
		}
		return built;
	}

	async save(params?: { refreshAfterSaved: boolean }) {
		try {
			await this.buildConfig();
			await firstValueFrom(this.configApi.saveConfig({ config: this.config }));
			if (params?.refreshAfterSaved) {
				await this.fetchConfig(this.config.id);
			}
		} catch (error) {
			this.snackbar.open((error as Error).message);
		}
	}

	async deploy(params?: { refreshAfterDeployed: boolean }) {
		try {
			await this.buildConfig();
			await firstValueFrom(this.radialConfigApi.deployConfig(this.config.id));
			if (params?.refreshAfterDeployed) {
				await this.fetchConfig(this.config.id);
			}
		} catch (error) {
			this.snackbar.open((error as Error).message);
		}
	}

	async fetchConfig(configId: number) {
		// Reset related data before get config
		this.configModelService.configModels = [];
		this.schemaSelectionService.unselect();
		this.configModified = false;

		this.loadingConfigSubject.next(true);
		this.fetchingConfigSubject.next(true);
		try {
			const config: Config | undefined = await this.getConfig(configId);
			if (config) {
				const connected: boolean = await this.connectConfig(config);
				if (connected) {
					this.refreshData();
				}
			}
		} catch (error) {
			this.snackbar.open(`Error during fetching config : ${(error as Error).message}`, 'Ok');
		} finally {
			this.loadingConfigSubject.next(false);
			this.fetchingConfigSubject.next(false);
		}
	}

	async getConfig(configId: number): Promise<Config | undefined> {
		let config;
		if (configId) {
			try {
				config = await firstValueFrom(this.configApi.getConfigById(configId));
			} catch (error) {
				this.snackbar.open('Error when importing config', 'Ok');
			}
		}
		return config;
	}

	async connectConfig(config: Config) {
		let connected: boolean = false;
		this.config = config;

		this.lockedConfigSubject.next(config.locked ?? false);

		if (this.config.schema && this.config.schema.body !== '') {
			const schemaResult = Schema.fromJson(this.config.schema.body);
			if (schemaResult.isOk()) {
				this.editedSchema = schemaResult.value!;
				this.editedSchemaId = this.config.schema.id;
				if (this.editedSchemaId && this.editedSchemaId !== '') {
					const linkedModels = await this.linkModels();
					connected = linkedModels > -1;
				}
			} else {
				throw schemaResult.error;
			}
		} else {
			try {
				// Create new schema and get id if not exist in config
				const newSchema = new Schema();
				newSchema.appendFile('subscriber', new SchemaFile());
				this.editedSchema = newSchema;
				const schemaRes = await firstValueFrom(this.schemaApi.createSchema({ body: JSON.stringify(this.editedSchema) }));
				this.editedSchemaId = schemaRes.id;
				if (this.editedSchemaId && this.editedSchemaId !== '') {
					connected = true;
				}
			} catch (error) {
				this.snackbar.open('Error when creating schema', 'Ok');
			}
		}
		return connected;
	}

	async linkModels(): Promise<number> {
		let linkedModelsCount = 0;
		// Link models
		if (this.config.models) {
			const fileKey = this.getSchemaFileKey(this.editedSchema);
			try {
				const modelPromises = this.config.models.map(async model => {
					const docKey = this.configModelService.getModelDocKey(model);
					const pageKey = this.configModelService.getModelPageKey(model);
					return this.configModelService.linkModel(model, { fileKey, docKey, pageKey });
				});

				const results = await Promise.allSettled(modelPromises);
				if (results.length >= 0) {
					linkedModelsCount = results.length;
					await this.configModelService.modelGroups(this.editedSchema);
				} else {
					linkedModelsCount = -1;
				}
			} catch (error) {
				this.snackbar.open(`An error occurred while linking models: ${error}`);
			}
		}
		return linkedModelsCount;
	}

	async removeModels(selectedModels: Model[]) {
		this.loadingConfigSubject.next(true);
		if (selectedModels && selectedModels.length > 0) {
			await Promise.allSettled(selectedModels.map(model => this.removeModel(model.id)));
			await this.configModelService.modelGroups(this.editedSchema);
			this.refreshData();
			this.configModified = true;
		}
		this.loadingConfigSubject.next(false);
	}

	async removeModel(modelId: string, withRefresh?: boolean) {
		let removed: boolean = false;

		const foundLinkedModel: LinkedModel = this.configModelService.findLinkedModelById(modelId);
		let linkedModelsFromKeys: LinkedModel[] = this.configModelService.findLinkedModels(
			foundLinkedModel.keys,
			this.schemaSelectionService.getSchemaLevel(foundLinkedModel.keys)
		);

		if (foundLinkedModel) {
			// If it's not the last to be in schemaNode, remove only related fields and unlink
			if (linkedModelsFromKeys.length > 1) {
				// Remove related fields in schema
				const modelFieldIds: string[] = foundLinkedModel.fields || [];
				for (const fieldKey of modelFieldIds) {
					// Find if this key is alone without this current model,
					// If it's the case, we have to delete the correspondant schema node
					const listWithoutCurrModel = foundLinkedModel.sourceGroupModelFields[fieldKey].filter(
						(modelId: string) => modelId !== foundLinkedModel.model.id
					);

					if (listWithoutCurrModel.length === 0) {
						// Remove fields
						this.removeSchemaNode({ ...foundLinkedModel.keys, fieldKey });
					}
				}
			}
			// If it's the last one in schemaNode, remove schemaNode (and unlink)
			if (linkedModelsFromKeys.length === 1) {
				this.removeSchemaNode(foundLinkedModel.keys);
			}
			this.configModelService.unlinkModelById(modelId);

			removed = !removed;
		}
		if (withRefresh) {
			this.refreshData();
		}
		return removed;
	}

	async addModels(models: Model[]) {
		this.loadingConfigSubject.next(true);
		if (models && models.length > 0) {
			await Promise.allSettled(models.map(model => this.addModel(model)));
			await this.configModelService.modelGroups(this.editedSchema);
			this.refreshData();
			this.configModified = true;
		}
		this.loadingConfigSubject.next(false);
	}

	async addModel(model: Model): Promise<boolean> {
		let added: boolean = false;

		const modelFields = await this.configModelService.getModelFields(model);
		const fileKey = this.getSchemaFileKey(this.editedSchema);
		const docKey = this.configModelService.getModelDocKey(model);
		const pageKey = this.configModelService.getModelPageKey(model);
		
		if (!this.configModelService.containsModel(model.id)) {
			this.configModelService.linkModel(model, { fileKey, docKey, pageKey });

			// Document
			if (!this.editedSchema.files[fileKey].containsDocument(docKey)) {
				const document = new SchemaDocument({}, docKey, '', '', [model.docType!.category.name]);
				const page = new SchemaPage({}, undefined, undefined, pageKey);
				document.appendPage(pageKey, page);
				this.setSchemaDoc({ fileKey, docKey }, document);

				if (!this.isFetching()) {
					// Add required from parent when creating node
					this.configConstr.addNodeRequirements(this.editedSchema.files[fileKey], docKey, 'RequireDocuments', FileConstraintRegistor);
					this.configConstr.addNodeRequirements(this.editedSchema.files[fileKey].documents[docKey], pageKey, 'RequirePages', DocumentConstraintRegistor);
				}
			}

			// Page
			if (!this.editedSchema.files[fileKey].documents[docKey].containsPage(pageKey)) {
				const page = new SchemaPage({}, undefined, undefined, pageKey);
				this.setSchemaPage({ fileKey, docKey, pageKey }, page);

				if (!this.isFetching()) {
					// Add required from parent when creating node
					this.configConstr.addNodeRequirements(this.editedSchema.files[fileKey].documents[docKey], pageKey, 'RequirePages', DocumentConstraintRegistor);
				}
			}

			// Fields
			for (const modelField of modelFields) {
				const fieldKey = modelField.name;
				if (!this.editedSchema.files[fileKey].documents[docKey].pages[pageKey].containsField(fieldKey)) {
					this.setSchemaFieldByModelField({ fileKey, docKey, pageKey, fieldKey }, modelField);
				}
			}
			added = !added;
		}
		return added;
	}

	convertModelField(modelField: ModelField): SchemaField | undefined {
		if (TYPE_TRANSFORMER[modelField.type]) {
			return new SchemaField(TYPE_TRANSFORMER[modelField.type], '', 0, modelField.name);
		}
		return
	}

	// * REMOVE -------------------------
	removeSchemaNode(keys: SchemaKeys) {
		const level = this.schemaSelectionService.getSchemaLevel(keys);
		const removeFn = {
			file: () => this.removeSchemaFile(keys),
			document: () => this.removeSchemaDoc(keys),
			page: () => this.removeSchemaPage(keys),
			field: () => this.removeSchemaField(keys)
		}
		removeFn[level]();

		this.configModified = true;
		this.configModelService.unlinkModels(keys, level);
		this.refreshData();
	}
	removeSchemaFile(keys: SchemaKeys) {
		this.editedSchema.removeFile(keys.fileKey!);
	}
	removeSchemaDoc(keys: SchemaKeys) {
		this.editedSchema.files[keys.fileKey!]
			.removeDocument(keys.docKey!);
			// Remove constraint/required from parent
		this.configConstr.removeNodeRequirements(this.editedSchema.files[keys.fileKey!], keys.docKey!, 'RequireDocuments');
	}
	removeSchemaPage(keys: SchemaKeys) {
		this.editedSchema.files[keys.fileKey!]
			.documents[keys.docKey!]
			.removePage(keys.pageKey!);
			// Remove constraint/required from parent
			this.configConstr.removeNodeRequirements(this.editedSchema.files[keys.fileKey!].documents[keys.docKey!], keys.pageKey!, 'RequirePages');

		if (!this.hasChildren(this.editedSchema.files[keys.fileKey!].documents[keys.docKey!].pages)) {
			const { fileKey, docKey } = keys;
			const parentKeys = { fileKey, docKey };
			this.removeSchemaNode(parentKeys);
		}
	}

	removeSchemaField(keys: SchemaKeys) {
		this.editedSchema.files[keys.fileKey!]
			.documents[keys.docKey!]
			.pages[keys.pageKey!]
			.removeField(keys.fieldKey!);

		if (!this.hasChildren(this.editedSchema
			.files[keys.fileKey!]
			.documents[keys.docKey!]
			.pages[keys.pageKey!]
			.fields)) {
			const { fileKey, docKey, pageKey } = keys;
			const parentKeys = { fileKey, docKey, pageKey };
			this.removeSchemaNode(parentKeys);
		}
	}

	// * SET -------------------------
	setSchemaNode(keys: SchemaKeys, schemaNode: SchemaNode) {
		const level = this.schemaSelectionService.getSchemaLevel(keys);
		const setFn = {
			file: () => this.setSchemaFile(keys, schemaNode as SchemaFile),
			document: () => this.setSchemaDoc(keys, schemaNode as SchemaDocument),
			page: () => this.setSchemaPage(keys, schemaNode as SchemaPage),
			field: () => this.setSchemaField(keys, schemaNode as SchemaField)
		}
		setFn[level]();

		this.configModified = true;
		this.refreshData();
	}
	setSchemaFile(keys: SchemaKeys, schemaFile: SchemaFile) {
		const newFile = SchemaFile.fromJson(schemaFile);
		if (newFile.isOk()) {
			this.editedSchema.appendFile(keys.fileKey!, newFile.value!);
		}
	}
	setSchemaDoc(keys: SchemaKeys, schemaDocument: SchemaDocument) {
		const newDoc = SchemaDocument.fromJson(schemaDocument);
		if (newDoc.isOk()) {
			this.editedSchema.files[keys.fileKey!].appendDocument(keys.docKey!, newDoc.value!);
		}
	}
	setSchemaPage(keys: SchemaKeys, schemaPage: SchemaPage) {
		const newPage = SchemaPage.fromJson(schemaPage);
		if (newPage.isOk()) {
			this.editedSchema.files[keys.fileKey!].documents[keys.docKey!].appendPage(keys.pageKey!, newPage.value!);
		}
	}
	setSchemaField(keys: SchemaKeys, schemaField: SchemaField) {
		const newfield = SchemaField.fromJson(schemaField);
		if (newfield.isOk()) {
			this.editedSchema.files[keys.fileKey!]
			.documents[keys.docKey!]
			.pages[keys.pageKey!]
			.appendField(keys.fieldKey!, newfield.value!);
		}
	}
	setSchemaFieldByModelField(keys: SchemaKeys, modelField: ModelField) {
		const schemaField = this.convertModelField(modelField);
		if (schemaField) {
			this.setSchemaField(keys, schemaField);
		}
	}

	// GET -------------------------
	selectModels(models: Model[]) {
		this.selectedModelsSubject.next(models);
	}

	refreshData() {
		this.refreshedDataSubject.next({
			schema: this.editedSchema,
			linkedModels: this.configModelService.configModels
		});
		// Update content with models with a new set selection
		this.schemaSelectionService.refreshSelection(this.editedSchema);
	}

	// SELECT -----------------------------------
	selectNode(schemaKeys: SchemaKeys) {
		this.schemaSelectionService.selectNode(this.editedSchema, schemaKeys);
	}

	hasChildren(object: any) {
		return Object.keys(object).length > 0;
	}

	getSchemaFileKey(schema: Schema) {
		return Object.keys(schema.files)[0] ?? undefined;
	}

	isFetching() {
		return this.fetchingConfigSubject.getValue();
	}
}