import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConfigCrudService } from 'src/app/services/crud/config-crud.service';
import { Stage } from 'src/app/models/stage.model';
import { firstValueFrom } from 'rxjs';
import { getNodeIcon } from 'src/app/models/global.model';

export interface DialogConfirmDeployConfigData {
  stage?: Stage;
  configName: string;
  configId: number;
}

@Component({
  selector: 'app-dialog-confirm-deploy-config-dialog',
  templateUrl: './dialog-confirm-deploy-config-dialog.component.html',
  styleUrl: './dialog-confirm-deploy-config-dialog.component.scss'
})
export class DialogConfirmDeployConfigDialogComponent {
  icon: string = '';
  deploying: boolean = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogConfirmDeployConfigData,
  private matDialog: MatDialogRef<DialogConfirmDeployConfigDialogComponent>,
  private configApi: ConfigCrudService,
  private snackbar: MatSnackBar)
  {
    this.icon = getNodeIcon('config');
  }

  async confirm() {
    try {
      this.deploying = true;
      await firstValueFrom(this.configApi.deployConfig(this.data.configId));
      this.snackbar.open(`Déploiement de ${this.data.configName} en ${this.data.stage ? this.data.stage.name : 'N/A'} effectuée.`, 'Ok');
      this.matDialog.close(true);
    } catch (error) {
      this.snackbar.open((error as Error).message);
    } finally {
      this.deploying = false;
    }
  }
}
