@if (user) {
  <mat-toolbar>
  @if (isInProjects()) {
    <button
      mat-icon-button
      matTooltip="Account settings"
      i18n-matTooltip
      aria-label="Open configurations menu"
      (click)="toggleDrawer()">
      <mat-icon>{{ openedDrawer ? 'first_page' : 'last_page'}}</mat-icon>
    </button>
  } 

  <!-- Manager -->
  <button mat-button routerLink="" [class.selected]="isInProjects()">
    <mat-icon>photo_filter</mat-icon>
    <span>Manager</span>
  </button>
  <!-- Dashboard -->
  <!-- <button mat-button disabled [class.selected]="router.url.includes('dashboard')" routerLink="dashboard" >
    <mat-icon>bar_chart</mat-icon>
    <span i18n>Dashboard</span>
  </button> -->
  <!-- Admin -->
   <!-- ! Put [class.visible]="admin" -->
  <button mat-button 
  [class.selected]="router.url.includes('admin')" routerLink="admin">
    <mat-icon>person</mat-icon>
    <span i18n>Admin</span>
  </button>
  <span class="spacer"></span>

   <!-- Import -->
    <!-- ! Put [class.visible]="admin" -->
   <button mat-icon-button (click)="openMigrationDialog()">
    <mat-icon>vertical_align_bottom</mat-icon>
  </button>

   <!-- Theme picker -->
   <button mat-icon-button  (click)="toggleTheme()">
    <mat-icon>{{ theme === 'light' ? ' light_mode' : 'dark_mode' }}</mat-icon>
  </button>

  <!-- Notifications -->
  <!-- <button mat-icon-button aria-label="Notifications" routerLink="notifications" disabled
  [class.selected]="router.url.includes('notifications')">
    <mat-icon>notifications</mat-icon>
  </button> -->
  
	<span class="avatar-container" [matMenuTriggerFor]="menu">
		<div class="avatar-circle">
			<!-- @if (user.avatar) {
        <img class="avatar-img" [src]="user.avatar" alt="Avatar">
      } @else if (user.name){
        <span class="avatar-letter">{{ user.name[0] }}</span>
      } -->
      @if (user.name) {
        <span class="avatar-letter">{{ user.name[0] }}</span>
      }
		</div>
	</span>
</mat-toolbar>

<mat-menu #menu="matMenu" class="menu">
  <div mat-menu-item disableRipple="true">
    <div class="menu-user">
      <div class="avatar-circle">
        <!-- @if (user.avatar) {
          <img class="avatar-img" [src]="user.avatar" alt="Avatar">
        } @else if (user.name) {
          <span class="avatar-letter">{{ user.name[0] }}</span>
        } -->
        @if (user.name) {
          <span class="avatar-letter">{{ user.name[0] }}</span>
        }
      </div>
      <div class="info-user">
        <div>{{ user.name }}</div>
        <div>{{ user.mail }}</div>
      </div>
    </div>
  </div>
  <mat-divider></mat-divider>
  <button mat-menu-item routerLink="profile/settings">
    <mat-icon>person_outline</mat-icon>
    <span i18n>Settings</span>
  </button>
  <button mat-menu-item (click)="logout()">
    <mat-icon>exit_to_app</mat-icon>
    <span i18n>Log out</span>
  </button>
</mat-menu>

}