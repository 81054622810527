export interface ItemData {
	id: any;
	name: string;
	// Dates
	createdAt?: string;
	updatedAt?: string;
	// description: string;
}

export type dataOptions = 'create' | 'delete' | 'update' | 'duplicate' | 'rename';

// During navigation, cancel some request and generate generic message
export const CANCELED_REQUEST = 'Cancel error';