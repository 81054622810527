import { Injectable } from '@angular/core';
import Konva from 'konva';
import { Stage } from 'konva/lib/Stage';

// ! https://konvajs.org/docs/sandbox/Objects_Snapping.html

@Injectable({
  providedIn: 'root'
})
export class KonvaSnappingService {
  readonly GUIDELINE_OFFSET: number = 5;

  constructor() { }

  getLineGuideStops(stage: Stage, skipShape: Konva.Shape): { vertical: number[]; horizontal: number[] } {
    const vertical: number[] = [0, stage.width() / 2, stage.width()];
    const horizontal: number[] = [0, stage.height() / 2, stage.height()];
  
    stage.find('Group').forEach((guideItem: Konva.Node) => {
      if (guideItem === skipShape) {
        return;
      }
      const box = guideItem.getClientRect();
      vertical.push(box.x, box.x + box.width, box.x + box.width / 2);
      horizontal.push(box.y, box.y + box.height, box.y + box.height / 2);
    });
  
    return {
      vertical: vertical.flat(),
      horizontal: horizontal.flat(),
    };
  }
  
  getObjectSnappingEdges(node: Konva.Node): { vertical: any[]; horizontal: any[] } {
    const box = node.getClientRect();
    const absPos: Konva.Vector2d = node.absolutePosition();
  
    return {
      vertical: [
        { guide: Math.round(box.x), offset: Math.round(absPos.x - box.x), snap: 'start' },
        { guide: Math.round(box.x + box.width / 2), offset: Math.round(absPos.x - box.x - box.width / 2), snap: 'center' },
        { guide: Math.round(box.x + box.width), offset: Math.round(absPos.x - box.x - box.width), snap: 'end' },
      ],
      horizontal: [
        { guide: Math.round(box.y), offset: Math.round(absPos.y - box.y), snap: 'start' },
        { guide: Math.round(box.y + box.height / 2), offset: Math.round(absPos.y - box.y - box.height / 2), snap: 'center' },
        { guide: Math.round(box.y + box.height), offset: Math.round(absPos.y - box.y - box.height), snap: 'end' },
      ],
    };
  }
  
  getGuides(lineGuideStops: { vertical: number[]; horizontal: number[] }, itemBounds: { vertical: any[]; horizontal: any[] }): any[] {
    const resultV: any[] = [];
    const resultH: any[] = [];
  
    lineGuideStops.vertical.forEach((lineGuide: number) => {
      itemBounds.vertical.forEach((itemBound: any) => {
        const diff: number = Math.abs(lineGuide - itemBound.guide);
        if (diff < this.GUIDELINE_OFFSET) {
          resultV.push({ lineGuide, diff, snap: itemBound.snap, offset: itemBound.offset });
        }
      });
    });
  
    lineGuideStops.horizontal.forEach((lineGuide: number) => {
      itemBounds.horizontal.forEach((itemBound: any) => {
        const diff: number = Math.abs(lineGuide - itemBound.guide);
        if (diff < this.GUIDELINE_OFFSET) {
          resultH.push({ lineGuide, diff, snap: itemBound.snap, offset: itemBound.offset });
        }
      });
    });
  
    const guides: any[] = [];
  
    const minV: any = resultV.sort((a, b) => a.diff - b.diff)[0];
    const minH: any = resultH.sort((a, b) => a.diff - b.diff)[0];
  
    if (minV) {
      guides.push({ lineGuide: minV.lineGuide, offset: minV.offset, orientation: 'V', snap: minV.snap });
    }
    if (minH) {
      guides.push({ lineGuide: minH.lineGuide, offset: minH.offset, orientation: 'H', snap: minH.snap });
    }
  
    return guides;
  }
  
  drawGuides(layer: Konva.Layer, guides: any[]): void {
    guides.forEach((lg) => {
      const line: Konva.Line = new Konva.Line({
        points: lg.orientation === 'H' ? [-6000, 0, 6000, 0] : [0, -6000, 0, 6000],
        stroke: 'rgb(0, 161, 255)',
        strokeWidth: 1,
        name: 'guid-line',
        dash: [4, 6],
      });
  
      layer.add(line);
      line.absolutePosition({ x: lg.orientation === 'H' ? 0 : lg.lineGuide, y: lg.orientation === 'H' ? lg.lineGuide : 0 });
    });
  }
  
  findGuidLines(stage: Konva.Stage, layer: Konva.Layer, event: any) {
    layer.find('.guid-line').forEach((l) => l.destroy());
  
    const lineGuideStops: { vertical: number[]; horizontal: number[] } = this.getLineGuideStops(stage, event.target);
    const itemBounds: { vertical: any[]; horizontal: any[] } = this.getObjectSnappingEdges(event.target);
  
    const guides: any[] = this.getGuides(lineGuideStops, itemBounds);
  
    if (!guides.length) {
      return;
    }
  
    this.drawGuides(layer, guides);
  
    const absPos: Konva.Vector2d = event.target.absolutePosition();
    guides.forEach((lg) => {
      switch (lg.snap) {
        case 'start': {
          absPos.x = lg.lineGuide + lg.offset;
          absPos.y = lg.lineGuide + lg.offset;
          break;
        }
        case 'center': {
          absPos.x = lg.lineGuide + lg.offset;
          absPos.y = lg.lineGuide + lg.offset;
          break;
        }
        case 'end': {
          absPos.x = lg.lineGuide + lg.offset;
          absPos.y = lg.lineGuide + lg.offset;
          break;
        }
      }
    });
    event.target.absolutePosition(absPos);
  }
  
  destroyGuidLines(layer: Konva.Layer) {
    layer.find('.guid-line').forEach((l) => l.destroy());
  }
}
