import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Model } from 'src/app/models/model/model.model';

export interface DialogDocumentEditorData {
  model: Model;
  readonly: boolean;
  preview: boolean;
}

@Component({
  selector: 'app-dialog-document-editor-dialog',
  templateUrl: './dialog-document-editor-dialog.component.html',
  styleUrl: './dialog-document-editor-dialog.component.scss'
})
export class DialogDocumentEditorDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogDocumentEditorData,
  private matDialog: MatDialogRef<DialogDocumentEditorDialogComponent>) 
  {}
}
