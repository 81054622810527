@if (canToggle) {
	<button mat-icon-button (click)="toggle = !toggle">
		<mat-icon>{{toggle ? 'keyboard_arrow_left' : 'keyboard_arrow_right' }}</mat-icon>
	</button>
}
@if (toggle) {
	@for (key of boxKeys; track $index) {
		<button mat-stroked-button [ngStyle]="getBoxStyles(key)" (click)="emitNewBox(key)"
		[matTooltip]="getBoxDescription(key)">{{ key }}</button>
	}    
}
