<div class="container-image">
	<span class="mat-title-small">Main</span>
	<div class="images">
		<div class="options mat-elevation-z8">
			<button mat-icon-button (click)="triggerFileInput('main')">
				<mat-icon>add</mat-icon>
			</button>
			<button mat-icon-button [disabled]="!mainImage" (click)="removeMainImage()">
				<mat-icon>delete</mat-icon>
			</button>
		</div>
		<img [src]="mainImage" class="selectable"/>
	</div>
	<mat-divider></mat-divider>
	<span class="mat-title-small">Alternates</span>
	<div class="images">
		<div class="options mat-elevation-z8">
			<button mat-icon-button (click)="triggerFileInput('alternative')">
				<mat-icon>add</mat-icon>
			</button>
			<button mat-icon-button [disabled]="!selectedAltImage" (click)="removeSelectedAltImage()">
				<mat-icon>delete</mat-icon>
			</button>
		</div>
		@for (image of altImages; track $index) {
			<img [src]="image" class="selectable" 
			(click)="selectedAltImage = $index" 
			[class.selected]="selectedAltImage === $index"/>
		}
	</div>
	<mat-divider></mat-divider>
	<span class="mat-title-small">Tests</span>
	<div class="images">
		<div class="options mat-elevation-z8">
			<button mat-icon-button (click)="triggerFileInput('test')">
				<mat-icon>add</mat-icon>
			</button>
			<button mat-icon-button [disabled]="!selectedTestImage" (click)="removeSelectedTestImage()">
				<mat-icon>delete</mat-icon>
			</button>
		</div>
		@for (image of testImages; track $index) {
			<img [src]="image" class="selectable"
			(click)="selectedTestImage = $index"
			[class.selected]="selectedTestImage === $index"/>
		}
	</div>
</div>

<input #fileInput type="file" [multiple]="typeImageBeingImported !== 'main'" 
name="file" accept="image/png, image/jpeg" (change)="onSelectedFiles($event)"
	style="display: none" />