<form [formGroup]="modelForm">
	
	@if (!creationMode) {
		<mat-form-field appearance="outline">
			<mat-label floatLabel="always" i18n>ID</mat-label>
			<input matInput [value]="model?.id ?? ''" readonly/>
		</mat-form-field>
	
		<mat-form-field appearance="outline">
			<mat-label floatLabel="always" i18n>Creation Date</mat-label>
			<input matInput [value]="model?.creationDate ?? '' | date: 'dd/MM/yyyy'" readonly/>
		</mat-form-field>
	
		<mat-form-field appearance="outline">
			<mat-label floatLabel="always" i18n>Modification Date</mat-label>
			<input matInput [value]="model?.modificationDate ?? '' | date: 'dd/MM/yyyy'" readonly/>
		</mat-form-field>
	}
	
	<mat-form-field appearance="outline" floatLabel="always" [class.last]="creationMode" [class.readonly]="automaticName && creationMode">
		<mat-label floatLabel="always" i18n>Name</mat-label>
		<input matInput formControlName="name" [placeholder]="automaticName ? 'Generated name...' : ''"/>
		@if (creationMode) {
			<button mat-icon-button matSuffix 
			matTooltip="Toggle automatic name generation"
			(click)="switchAutoName()" [class.selected]="automaticName" class="switchAuto">
				<mat-icon>autorenew</mat-icon>
			</button>
		}
	</mat-form-field>

	<mat-form-field appearance="outline" floatLabel="always">
	<mat-label i18n>Page</mat-label>
	<mat-select formControlName="page" name="language">
		<mat-option value="Recto">Recto</mat-option>
		<mat-option value="Verso">Verso</mat-option>
	</mat-select>
	</mat-form-field>

	@if (!creationMode) {
		<mat-form-field appearance="outline" floatLabel="always">
			<mat-label floatLabel="always" i18n>Type</mat-label>
			<input matInput formControlName="type"/>
		</mat-form-field>
	}

	<mat-form-field>
		<mat-label floatLabel="always" i18n>Language*</mat-label>
		<mat-select formControlName="languageId" name="language">
			@for (language of languages$ | async; track language.id) {
				<mat-option [value]="language.id">{{language.name}} ({{language.code}})</mat-option>
			}
		</mat-select>
	</mat-form-field>

	<mat-form-field>
		<mat-label floatLabel="always" i18n>Category*</mat-label>
		<mat-select formControlName="docTypeId" name="docType">
			@for (type of types$ | async; track type.id) {
				<mat-option [value]="type.id">{{type.name}}</mat-option>
			}
		</mat-select>
	</mat-form-field>

	@if (!creationMode) {
		<mat-form-field appearance="outline">
			<mat-label floatLabel="always" i18n>Validity Date</mat-label>
			<input matInput formControlName="validityDate" [matDatepicker]="datepicker"/>
			<mat-hint>DD/MM/YYYY</mat-hint>
			<mat-datepicker-toggle matIconSuffix [for]="datepicker"></mat-datepicker-toggle>
			<mat-datepicker #datepicker>
				<mat-datepicker-actions>
					<button mat-button matDatepickerCancel>Cancel</button>
					<button mat-raised-button matDatepickerApply>Apply</button>
				</mat-datepicker-actions>
			</mat-datepicker>
		</mat-form-field>
	}
	
	<div class="layout">
			<button mat-button (click)="toggle('mobile')" [class.selected]="modelForm.get('mobile')?.value">
				<mat-icon>{{ modelForm.get('mobile')?.value ? 'smartphone' : 'mobile_off' }}</mat-icon>
				<span>{{ modelForm.get('mobile')?.value ? 'Mobile' : 'Not mobile' }}</span>
			</button>

		<button mat-button (click)="toggle('public')" [class.selected]="modelForm.get('public')?.value">
			<mat-icon>{{ modelForm.get('public')?.value ? 'public' : 'public_off' }}</mat-icon>
			<span>{{ modelForm.get('public')?.value ? 'Public' : 'Private' }}</span>
		</button>

		<button mat-button (click)="toggle('shiny')" [class.selected]="modelForm.get('shiny')?.value">
			<mat-icon>{{ modelForm.get('shiny')?.value ? 'flash_on' : 'flash_off' }}</mat-icon>
			<span>{{ modelForm.get('shiny')?.value ? 'Shiny' : 'Not shiny' }}</span>
		</button>
	</div>
</form>
