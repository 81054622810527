import { Component, OnInit } from '@angular/core';
import { AuthService } from './services/auth.service';
import { UserService } from './services/user.service';
import { SocketService } from './services/socket.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  showLoader: boolean = false;

  constructor(
    public authService: AuthService,
    public userService: UserService,
    public socketService: SocketService)
  { }

  public async ngOnInit() {
    await this.authService.login();
    setTimeout(() => {
        this.showLoader = true; // Hide the loader after 10 seconds
    }, 4000);
  }
}
