import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { firstValueFrom } from 'rxjs';
import { Config } from 'src/app/models/config.model';
import { getNodeIcon } from 'src/app/models/global.model';
import { Stage } from 'src/app/models/stage.model';
import { ConfigCrudService, SaveConfigData } from 'src/app/services/crud/config-crud.service';

export interface DialogConfirmTransferConfigData {
  stage?: Stage;
  projectId: number;
  configName: string;
  configId: number;
}

@Component({
  selector: 'app-dialog-confirm-transfer-config-dialog',
  templateUrl: './dialog-confirm-transfer-config-dialog.component.html',
  styleUrl: './dialog-confirm-transfer-config-dialog.component.scss'
})
export class DialogConfirmTransferConfigDialogComponent {
  keepCopy: boolean = true;

  icon: string = '';

  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogConfirmTransferConfigData,
  private matDialog: MatDialogRef<DialogConfirmTransferConfigDialogComponent>,
  private configApi: ConfigCrudService,
  private snackbar: MatSnackBar)
  {
    this.icon = getNodeIcon('config');
  }

  async confirm() {
    try {
      let copiedConfig: Config = await firstValueFrom(this.configApi.getConfigById(this.data.configId));
      const copiedModelIds: string[] = copiedConfig.models?.map(model => model.id) || [];
      const copiedSchemaId: string = copiedConfig.schema!.id;

      const newConfig: Config = await firstValueFrom(this.configApi.createConfig(copiedConfig.name, this.data.stage?.id));
      await firstValueFrom(this.configApi.attachProject(newConfig.id, this.data.projectId));
      const configData: SaveConfigData = { configFragments: {
          configId: newConfig.id,
          schemaId: copiedSchemaId,
          modelIds: copiedModelIds
       }};
      await firstValueFrom(this.configApi.saveConfig(configData));

      this.snackbar.open(`Copie de ${this.data.configName} en ${this.data.stage ? this.data.stage.name : 'N/A'} effectuée.`, 'Ok', { duration: 3000 });
      this.matDialog.close(true);
    } catch (error) {
      this.snackbar.open((error as Error).message);
    }
  }
}
