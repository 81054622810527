import { Injectable, Renderer2, RendererFactory2, inject } from '@angular/core';
import Konva from 'konva';
import { Group } from 'konva/lib/Group';
import { Layer } from 'konva/lib/Layer';
import { KonvaEventObject } from 'konva/lib/Node';
import { Stage } from 'konva/lib/Stage';
import { Transformer } from 'konva/lib/shapes/Transformer';
import { KonvaSnappingService } from './konva-snapping.service';
import { MatMenuTrigger } from '@angular/material/menu';
import { ThemeService } from '../theme.service';
import { BOX_CONFIGS } from 'src/app/models/model/model.model';

@Injectable({
  providedIn: 'root'
})
export class KonvaEventService {
  private renderer: Renderer2;

  constructor(private konvaSnapping: KonvaSnappingService,
    private themeService: ThemeService
  ) {
    this.renderer = inject(RendererFactory2).createRenderer(null, null);
  }

  handleMouseDown(group: Group) {}
  handleMouseUp(group: Group) {}

  handleMouseLeave(layer: Layer, transformer: Transformer, stage: Stage, group: Group) {
    this.renderer.setStyle(stage.container(), 'cursor', 'auto');
    group.draggable(false);
    transformer.nodes([]);
    layer.draw();
  }

  hoverBox(stage: Konva.Stage, currentBox: Konva.Group) {
    this.removeHoverStates(stage);
    currentBox.setAttrs({hovered: true});
    currentBox.findOne('Rect')?.setAttrs({strokeWidth: 4, stroke: '#1E90FF'});
  }

  removeHoverStates(stage: Konva.Stage) {
    const boxes: Group[] = stage.children[0].find('Group');
    boxes.forEach((box) => {
      box.setAttrs({hovered: false});
      box.findOne('Rect')?.setAttrs({...BOX_CONFIGS[box.attrs.type]});
    });
  }

  // * ZOOM
  toggleZoom(event: KonvaEventObject<WheelEvent>, stage: Stage, scaleBy: number) {
    event.evt.preventDefault();
    
    const oldScale = stage.scaleX();
    const pointer = stage.getPointerPosition();
    
    const mousePointTo = {
      x: (pointer!.x - stage.x()) / oldScale,
      y: (pointer!.y - stage.y()) / oldScale,
    };
    
    let direction = event.evt.deltaY > 0 ? 1 : -1;
    const newScale = direction > 0 ? oldScale * scaleBy : oldScale / scaleBy;
    if (newScale >= 1.6 || newScale <= 0.1) return

    stage.scale({ x: newScale, y: newScale });
    
    const newPos = {
      x: pointer!.x - mousePointTo.x * newScale,
      y: pointer!.y - mousePointTo.y * newScale,
    };
    
    this.updateTextSizes(stage);
    
    stage.position(newPos);
    stage.batchDraw(); // Refresh after scale change
  }

  updateTextSizes(stage: Konva.Stage) {
    const scale = stage.scaleX();
    const groups: Konva.Group[] = stage.children[0].find('Group');

    groups.forEach((group: Konva.Group) => {
      const text = group.findOne('Text');
      if (text) {
        this.updateTextSize(text as Konva.Text, 18, scale);
      }
    });
  }

  updateTextSize(text: Konva.Text, baseSize: number, scale: number) {
    const newSize = baseSize / scale;
  
    text.fontSize(newSize);
    text.getLayer()!.batchDraw();
  }

  handleClick(group: Group, menuTrigger: MatMenuTrigger) {}

  // * COLLISION WITH MOVEMENT
  constrainBoxMovement(stage: Konva.Stage, image: Konva.Image, group: Konva.Group) {
    const boxPosition = group.position();
    const groupRect = group.getClientRect();
    
    const scale = stage.scaleX();
    
    const imageWidth = image.width();
    const imageHeight = image.height();
  
    const minX = image.x();
    const minY = image.y();
    
    const maxX = image.x() + imageWidth - groupRect.width / scale;
    const maxY = image.y() + imageHeight - groupRect.height / scale;
  
    let constrainedX = Math.max(minX, Math.min(boxPosition.x, maxX));
    let constrainedY = Math.max(minY, Math.min(boxPosition.y, maxY));

    // if (constrainedX !== boxPosition.x) {
    //   console.warn('Collision détectée avec la bordure horizontale');
    //   group.x(boxPosition.x);
    // }
    // if (constrainedY !== boxPosition.y) {
    //   console.warn('Collision détectée avec la bordure verticale');
    //   group.y(boxPosition.y);
    // }
  
    group.position({ x: constrainedX, y: constrainedY });
    
    // Refresh layer
    group.getLayer()!.batchDraw();
  }

  // ! COLLISION WITH RESIZE
  constrainBoxResize(stage: Konva.Stage, image: Konva.Image, group: Konva.Group) {
    const boxPosition = group.position();
    const scale = stage.scaleX();
    
    const imageWidth = image.width();
    const imageHeight = image.height();
    
    const minX = image.x();
    const minY = image.y();
    
    const groupRect = group.getClientRect();
    const scaledGroupWidth = groupRect.width / scale;
    const scaledGroupHeight = groupRect.height / scale;
    
    const maxX = image.x() + imageWidth - scaledGroupWidth;
    const maxY = image.y() + imageHeight - scaledGroupHeight;
    
    const constrainedX = Math.max(minX, Math.min(boxPosition.x, maxX));
    const constrainedY = Math.max(minY, Math.min(boxPosition.y, maxY));
  
    group.position({ x: constrainedX, y: constrainedY });
    group.width(scaledGroupWidth);
    group.height(scaledGroupHeight);
  
    group.getLayer()!.batchDraw();
  }

  updateDimensions(group: Konva.Group, rect: Konva.Rect, text: Konva.Text) {
    const limit: number = 20;
    const scaleX = group.scaleX();
    const scaleY = group.scaleY();
  
    let newWidth = Math.max(rect.width() * scaleX, 20);
    let newHeight = Math.max(rect.height() * scaleY, 20);

    // Can't be under 20 pixels
    if (newWidth <= limit) newWidth = limit;
    if (newHeight <= limit) newHeight = limit;
  
    rect.width(newWidth);
    rect.height(newHeight);
    group.width(newWidth);
    group.height(newHeight);

    group.scaleX(1);
    group.scaleY(1);
  
    this.updateTextPositionAndSize(text, newWidth, newHeight);
  }

  updateTextPositionAndSize(text: Konva.Text, newWidth: number, newHeight: number) {
    text.width(newWidth);
    text.height(newHeight);
    text.x(0);
    text.y((newHeight - text.height()) / 2);
  }
}


