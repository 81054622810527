<h2 mat-dialog-title>Suppression de {{ data.nodeName }}</h2>
<mat-dialog-content class="mat-typography">
	<span>Veuillez confirmer la suppression en réécrivant le nom de l'élément à supprimer.</span>
  <mat-form-field subscriptSizing="dynamic" floatLabel="always">
		<mat-label>Client à supprimer</mat-label>
		<input matInput #input>
	</mat-form-field>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Annuler</button>
  <button mat-raised-button (click)="confirm()" class="warn-color"
  [disabled]="input.value !== data.nodeName">
  Supprimer</button>
</mat-dialog-actions>
