import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { firstValueFrom } from 'rxjs';
import { ImageOutput } from 'src/app/models/model/image.model';
import { Model } from 'src/app/models/model/model.model';
import { ImageCrudService } from 'src/app/services/crud/model/image-crud.service';
import { ModelCrudService } from 'src/app/services/crud/model/model-crud.service';
import { DialogDocumentPostCreationDialogComponent, DialogDocumentPostCreationOutputData } from '../dialog-document-post-creation-dialog/dialog-document-post-creation-dialog.component';

export interface DialogDocumentCreatorData {

}

@Component({
  selector: 'app-dialog-document-creator-dialog',
  templateUrl: './dialog-document-creator-dialog.component.html',
  styleUrl: './dialog-document-creator-dialog.component.scss'
})
export class DialogDocumentCreatorDialogComponent {
  @ViewChild('fileInput', { static: true })
  fileInput!: ElementRef<HTMLInputElement>;

  // Drag and drop
	@ViewChild('dropZone')
	dropZone!: ElementRef<HTMLInputElement>;
	// Drag and drop files
	isDragging = false;

  model?: Model;
  
  mainImageFile?: File;
  mainImageUrl?: string = '';

  modelFormIsValid: boolean = false;

  preboxes: boolean = true;

  resetForm: boolean = false;

  // Indexed for mat progress bar (0/100)
  loading: boolean = false;
  loadingState: number = 0;

  get canConfirm() {
    return this.mainImageUrl !== '' && this.modelFormIsValid && !this.loading;
  }

  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogDocumentCreatorData,
  private matDialogRef: MatDialogRef<DialogDocumentCreatorDialogComponent>,
  private matDialog: MatDialog,
  private imageApi: ImageCrudService,
  private modelApi: ModelCrudService,
  private snackbar: MatSnackBar) {

  }

  triggerFileInput() {
    // Reset the value property to allow select multiple times the same file
		this.fileInput.nativeElement.value = '';
    // Load/Prepare files and then start
    this.fileInput.nativeElement.click();
  }

  importImage(options: {event?: any, file?: File}) {
    this.mainImageFile = options.event?.target.files[0] ?? options.file;
    if (this.mainImageFile && 
      (this.mainImageFile?.type === "image/png" || this.mainImageFile?.type === "image/jpeg")
    ) {
      this.mainImageUrl = URL.createObjectURL(this.mainImageFile);
    }
  }

  async confirm() {
    if (this.canConfirm) {
    try {
      this.loading = true;
      this.loadingState = 0;

      const imageOutput: ImageOutput = await firstValueFrom(this.imageApi.postImage('main', this.mainImageFile!, this.preboxes));
      if (imageOutput) {
        this.loadingState = 50;
        let model: Model = { ...this.model!, imageIds: [imageOutput.id, imageOutput.previewId!], rawBoxes: imageOutput.preboxes ?? [] };
        const createdModelOutput: { id: string } = await firstValueFrom(this.modelApi.createModel(model));
        if (createdModelOutput) {
          this.loadingState = 100;

          // We ask if the user wants to edit the current model in editor, find another to edit one, add a new model after it, or just cancel all.
          const dialogRef = this.matDialog.open(DialogDocumentPostCreationDialogComponent);
          dialogRef.afterClosed().subscribe(async (output: DialogDocumentPostCreationOutputData) => {
            if (output.continueToCreate) {
              this.resetCreation();
            }
            if (output.edit) {
              this.matDialogRef.close(createdModelOutput.id);
            }
            if (output.findAnotherModel) {
              this.matDialogRef.close();
            }
          });
        }
      }
    } catch (error) {
      this.snackbar.open('Error when importing config', 'Ok');
    } finally {
      this.loading = false;
    }
  }
  }

  resetCreation() {
    this.resetForm = true;
    delete this.mainImageFile;
    this.mainImageUrl = '';
    this.preboxes = true;
    delete this.model;
    this.modelFormIsValid = false;
  }

  /* Drag and drop files */
	async onDrop(event: DragEvent, onDocZone?: boolean) {
			event.preventDefault();
			this.dropZone.nativeElement.classList.remove('drag-over');
			this.isDragging = false;

			if (onDocZone && event.dataTransfer) {
				const file: File = event.dataTransfer.files.item(0)!;
			  this.importImage({ file });
		}
	}

  onDragOver(event: DragEvent): void {
		event.preventDefault();
		this.dropZone.nativeElement.classList.add('drag-over');
		this.isDragging = true;
	}

	onDragLeave(event: DragEvent): void {
		event.preventDefault();
		this.dropZone.nativeElement.classList.remove('drag-over');
		this.isDragging = false;
	}
}

