import { NgModule } from '@angular/core';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatMenuModule } from '@angular/material/menu';
import { MatInputModule } from '@angular/material/input';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule, MatOptionModule, MatRippleModule } from '@angular/material/core';
import { MatDividerModule } from '@angular/material/divider';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS, MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSortModule } from '@angular/material/sort';
import { MatListModule } from '@angular/material/list';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatRadioModule } from '@angular/material/radio';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatCardModule } from '@angular/material/card';


import { CdkMenuModule } from '@angular/cdk/menu';
import { DragDropModule } from '@angular/cdk/drag-drop';

import { MatSelectModule } from '@angular/material/select';
import { FirstLetterUppercasePipe } from './pipes/first-letter-uppercase.pipe';
import { CommonModule } from '@angular/common';
import { ConfigCardComponent } from './config-card/config-card.component';
import { DialogCreateConfigDialogComponent } from './dialogs/create/dialog-create-config-dialog/dialog-create-config-dialog.component';
import { DialogCreateProjectDialogComponent } from './dialogs/create/dialog-create-project-dialog/dialog-create-project-dialog.component';
import { DialogCreateCustomerDialogComponent } from './dialogs/create/dialog-create-customer-dialog/dialog-create-customer-dialog.component';
import { DialogDuplicateConfigDialogComponent } from './dialogs/duplicate/dialog-duplicate-config-dialog/dialog-duplicate-config-dialog.component';
import { DialogDuplicateProjectDialogComponent } from './dialogs/duplicate/dialog-duplicate-project-dialog/dialog-duplicate-project-dialog.component';
import { DialogDeleteConfigDialogComponent } from './dialogs/delete/dialog-delete-config-dialog/dialog-delete-config-dialog.component';
import { DialogDeleteProjectDialogComponent } from './dialogs/delete/dialog-delete-project-dialog/dialog-delete-project-dialog.component';
import { DialogAddUserDialogComponent } from './dialog-add-user-dialog/dialog-add-user-dialog.component';
import { DialogConfirmTransferConfigDialogComponent } from './dialogs/duplicate/dialog-confirm-transfer-config-dialog/dialog-confirm-transfer-config-dialog.component';
import { DialogDeleteCustomerDialogComponent } from './dialogs/delete/dialog-delete-customer-dialog/dialog-delete-customer-dialog.component';
import { StageCardComponent } from './stage-card/stage-card.component';
import { DialogConfirmDeployConfigDialogComponent } from './dialogs/edit/dialog-confirm-deploy-config-dialog/dialog-confirm-deploy-config-dialog.component';
import { DialogRenameCustomerDialogComponent } from './dialogs/edit/dialog-rename-customer-dialog/dialog-rename-customer-dialog.component';
import { DialogRenameProjectDialogComponent } from './dialogs/edit/dialog-rename-project-dialog/dialog-rename-project-dialog.component';
import { DialogRenameConfigDialogComponent } from './dialogs/edit/dialog-rename-config-dialog/dialog-rename-config-dialog.component';
import { DialogSaveConfigDialogComponent } from './dialogs/edit/dialog-save-config-dialog/dialog-save-config-dialog.component';
import { ToastModule } from 'primeng/toast';
import { DialogConfirmChoiceDialogComponent } from './dialogs/dialog-confirm-choice-dialog/dialog-confirm-choice-dialog.component';
import { CanvasJSAngularChartsModule } from '@canvasjs/angular-charts';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FilterPipe } from './pipes/filter.pipe';
import { DialogDocumentEditorDialogComponent } from './document-editor/dialog-document-editor-dialog/dialog-document-editor-dialog.component';
import { DocumentEditorComponent } from './document-editor/document-editor.component';
import { DocumentPanelComponent } from './document-editor/document-panel/document-panel.component';
import { SelectionPanelComponent } from './document-editor/selection-panel/selection-panel.component';
import { ToolbarComponent } from './document-editor/toolbar/toolbar.component';
import { DialogDocumentCreatorDialogComponent } from './document-editor/dialog-document-creator-dialog/dialog-document-creator-dialog.component';
import { ImagePanelComponent } from './document-editor/image-panel/image-panel.component';
import { ModelFormComponent } from './document-editor/model-form/model-form.component';
import { DialogDocumentPostCreationDialogComponent } from './document-editor/dialog-document-post-creation-dialog/dialog-document-post-creation-dialog.component';

const materialModules = [
  MatButtonModule,
	MatFormFieldModule,
	MatToolbarModule,
	MatIconModule,
	MatDatepickerModule,
	MatProgressBarModule,
	MatMenuModule,
  MatInputModule,
	MatTableModule,
	MatTabsModule, 
	MatCheckboxModule,
	MatChipsModule,
	MatDividerModule,
	MatRippleModule,
	MatSnackBarModule,
	MatTooltipModule,
	MatDialogModule,
	MatListModule,
	MatPaginatorModule,
	MatSortModule,
	MatSelectModule,
	MatOptionModule,
	MatProgressSpinnerModule,
	MatBadgeModule,
	MatAutocompleteModule,
	MatExpansionModule,
	MatRadioModule,
	CdkMenuModule,
	DragDropModule,
	MatButtonToggleModule,
	MatSlideToggleModule,
	MatCardModule,
	MatNativeDateModule
];

const dialogComponents = [
	DialogAddUserDialogComponent,

	DialogCreateConfigDialogComponent,
	DialogCreateProjectDialogComponent,
	DialogCreateCustomerDialogComponent,

	DialogDuplicateConfigDialogComponent,
	DialogConfirmTransferConfigDialogComponent,
	DialogDuplicateProjectDialogComponent,

	DialogDeleteConfigDialogComponent,
	DialogDeleteProjectDialogComponent,
	DialogDeleteCustomerDialogComponent,

	DialogRenameCustomerDialogComponent,
	DialogRenameProjectDialogComponent,
	DialogRenameConfigDialogComponent,

	DialogSaveConfigDialogComponent,
	DialogConfirmDeployConfigDialogComponent,
	DialogConfirmChoiceDialogComponent,

	DialogDocumentEditorDialogComponent,
	DialogDocumentPostCreationDialogComponent,
	DialogDocumentCreatorDialogComponent
];

const formsModules = [
	FormsModule,
	ReactiveFormsModule
];

const customComponents = [
	ConfigCardComponent,
	StageCardComponent,

	DocumentEditorComponent,
	DocumentPanelComponent,
	SelectionPanelComponent,
	ImagePanelComponent,
	ModelFormComponent,
	ToolbarComponent
];

const externalModules = [
	ToastModule,
	CanvasJSAngularChartsModule
];

@NgModule({
	declarations: [
		...dialogComponents,

		...customComponents,

		FirstLetterUppercasePipe,
		FilterPipe
	],
  imports: [CommonModule, ...formsModules, ...materialModules],
  exports: [CommonModule, ...formsModules, ...materialModules, ...dialogComponents, ...customComponents, ...externalModules],
	providers: [
		{ provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 2500 }}
	]
})
export class SharedModule { }