import { Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { ImageType, ModelImage } from 'src/app/models/model/image.model';
import { ImageCrudService } from 'src/app/services/crud/model/image-crud.service';

@Component({
  selector: 'app-image-panel',
  templateUrl: './image-panel.component.html',
  styleUrl: './image-panel.component.scss'
})
export class ImagePanelComponent implements OnChanges {
  @ViewChild('fileInput', { static: true })
  fileInput!: ElementRef<HTMLInputElement>;

  @Input() images?: ModelImage[] | undefined;
  @Output() imagesChange = new EventEmitter<ModelImage[]>();

  mainImage: string = '';
  mainImageBeingImported: boolean = false;

  altImages: string[] = [];
  selectedAltImage: number = 0;
  altImageBeingImported: boolean = false;

  testImages: string[] = [];
  selectedTestImage: number = 0;
  testImageBeingImported: boolean = false;

  typeImageBeingImported?: ImageType;

  constructor(private imageApi: ImageCrudService) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['images']) {
      this.updateImages();
    }
  }

  updateImages() {
    if (this.images) {
      this.mainImage = '';
      this.altImages = [];
      this.testImages = [];

      console.warn(this.images)

      const imagePromises = this.images!.map((image) => this.loadImage(image));

      Promise.allSettled(imagePromises).then(() => {});
    }
  }

  async loadImage(image: ModelImage) {
    try {
      const importedImage = await firstValueFrom(this.imageApi.getImageById(image.id));
      switch (image.type) {
        case 'main':
          this.mainImage = importedImage;
        break;
        case 'alternative':
          this.altImages.push(importedImage);
        break;
        case 'test':
          this.testImages.push(importedImage);
        break;
      }
    } catch (error) {
      
    }
  }

  removeMainImage() {
    this.mainImage = '';
  }

  removeSelectedAltImage() {
    this.altImages.splice(this.selectedAltImage, 1);
    this.selectedAltImage = 0;
  }

  removeSelectedTestImage() {
    this.testImages.splice(this.selectedTestImage, 1);
    this.selectedTestImage = 0;
  }

  triggerFileInput(type: ImageType) {
    this.typeImageBeingImported = type;
    // Reset the value property to allow select multiple times the same file
		this.fileInput.nativeElement.value = '';
			// Load/Prepare files and then start
		this.fileInput.nativeElement.click();
  }

  async onSelectedFiles(event: any) {
		const fileList = event?.target?.files as FileList;
    const files: File[] = this.getAllImageTypes(fileList) ?? [];
		if (files.length === 0) {
			return;
		}

    const imagePromises = files.map((file) => this.addImage(file));

    Promise.allSettled(imagePromises).then(() => {});
	}

  private async addImage(file: File): Promise<void> {
    const imageOutput = await firstValueFrom(this.imageApi.postImage(this.typeImageBeingImported!, file, false));
    const image = await firstValueFrom(this.imageApi.getImageById(imageOutput.id));
    console.warn(image)

    const reader = new FileReader();
  
    const base64String = await new Promise<string>((resolve, reject) => {
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(file);
    });

    switch (this.typeImageBeingImported) {
      case 'main':
        this.mainImage = base64String;
        break;
      case 'alternative': 
        this.altImages.push(base64String);
        break;
      case 'test': 
      this.testImages.push(base64String);
        break;
    }
  }

  getAllImageTypes(fileList: FileList): File[] | null {
    const images: File[] = [];

    for (let i = 0; i < fileList.length; i++) {
        const file = fileList[i];
        if (file.type === "image/png" || file.type === "image/jpeg") {
          images.push(file);
        } else {
            return null;
        }
    }
    return images;
  }
}
