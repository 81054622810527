import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { firstValueFrom } from 'rxjs';
import { Customer } from 'src/app/models/customer.model';
import { getNodeIcon } from 'src/app/models/global.model';
import { CustomerCrudService } from 'src/app/services/crud/customer-crud.service';

export interface DialogRenameCustomerData {
  nodeId: number;
  nodeName: string;
}

@Component({
  selector: 'app-dialog-rename-customer-dialog',
  templateUrl: './dialog-rename-customer-dialog.component.html',
  styleUrl: './dialog-rename-customer-dialog.component.scss'
})
export class DialogRenameCustomerDialogComponent {
  icon: string = '';

  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogRenameCustomerData,
  private matDialog: MatDialogRef<DialogRenameCustomerDialogComponent>,
  private customerApi: CustomerCrudService,
  private snackbar: MatSnackBar) 
  {
    this.icon = getNodeIcon('customer');
  }

  async confirm(newName: string) {
    try {
      let renamedCustomer = await firstValueFrom(this.customerApi.editCustomer(
        this.data.nodeId, { name: newName }
      ));
      this.snackbar.open(`Client ${this.data.nodeName} renommé en ${renamedCustomer.name}.`, 'Ok');
      this.matDialog.close(renamedCustomer);
    } catch (error) {
      this.snackbar.open((error as Error).message);
    }
  }
}

