import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { firstValueFrom } from 'rxjs';
import { Stage } from 'src/app/models/stage.model';
import { ConfigCrudService } from 'src/app/services/crud/config-crud.service';

export interface DialogDeleteConfigData {
  nodeName: string;
  nodeId: number;
  nodeParentName: string;
  stage?: Stage;
}

@Component({
  selector: 'app-dialog-delete-config-dialog',
  templateUrl: './dialog-delete-config-dialog.component.html',
  styleUrl: './dialog-delete-config-dialog.component.scss'
})
export class DialogDeleteConfigDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogDeleteConfigData,
  private matDialog: MatDialogRef<DialogDeleteConfigDialogComponent>,
  private configApi: ConfigCrudService,
  private snackbar: MatSnackBar) 
  {}

  async confirm() {
    try {
      const deletedConfig = await firstValueFrom(this.configApi.deleteConfig(this.data.nodeId));
      this.snackbar.open(`Configuration ${this.data.nodeName} supprimée.`, 'Ok', { duration: 3000 });
      this.matDialog.close(deletedConfig);
    } catch (error) {
      this.snackbar.open((error as Error).message);
    }
  }
}
