import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BOX_CONFIGS, getBoxDescription, getBoxStyles, RawBox } from 'src/app/models/model/model.model';

@Component({
  selector: 'app-selection-panel',
  templateUrl: './selection-panel.component.html',
  styleUrl: './selection-panel.component.scss'
})
export class SelectionPanelComponent implements OnInit, OnChanges {
  @Input() box?: RawBox;
  @Output() boxChange = new EventEmitter<RawBox>();
  @Output() removed = new EventEmitter<number>();

  boxForm!: FormGroup;
  boxKeys = Object.keys(BOX_CONFIGS);

  readonly defaultFormValues = {
    name: [''],
    type: [''],
    x: [],
    y: [],
    width: [],
    height: []
  }

  constructor(private fb: FormBuilder) {}

  ngOnInit() {
    this.initForm();

    this.boxForm.valueChanges.subscribe((value: RawBox) => {
      this.boxChange.emit({...value, key: this.box!.key});
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['box']) {
      this.updateForm();
    }
  }

  initForm() {
    this.boxForm = this.fb.group(this.defaultFormValues);
  }

  updateForm() {
    if (this.boxForm) {
      this.boxForm.patchValue(this.box ?? this.defaultFormValues, { emitEvent: false });
    }
  }

  getBoxStyles(key: string) {
    return getBoxStyles(key);
  }

  getBoxDescription(key: string) {
    return getBoxDescription(key);
  }
  
  removeBox() {
    if (this.box) {
      this.removed.emit(this.box.key);
    }
  }
}
