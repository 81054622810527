<h2 mat-dialog-title>
	<span>Déploiement de {{ data.configName }}</span>
</h2>
<mat-dialog-content class="mat-typography">
	@if (deploying) {
		<mat-spinner></mat-spinner>
	} @else {
		<p>Déployer <span>{{ data.configName }}</span> dans 
			@if (data.stage) {
				<app-stage-card [stage]="data.stage"></app-stage-card>
			} @else {
				N/A
			} ?
		</p>
	}
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Annuler</button>
  <button mat-raised-button (click)="confirm()">
  Appliquer</button>
</mat-dialog-actions>
