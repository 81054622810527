<h2 mat-dialog-title> 
	<span>Renommage de {{ data.nodeName }}</span>
</h2>
<mat-dialog-content class="mat-typography">
	<!-- Name -->
  <mat-form-field subscriptSizing="dynamic" floatLabel="always">
		<mat-label>Nouveau nom</mat-label>
		<input matInput #input>
	</mat-form-field>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Annuler</button>
  <button mat-raised-button (click)="confirm(input.value)" 
  [disabled]="!input.value || input.value === data.nodeName">
  Confirmer</button>
</mat-dialog-actions>
