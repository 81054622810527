<span class="toolbar mat-elevation-z2">
  @if (!readonlyMode) {
    <button mat-icon-button (click)="konvaService.undo()">
      <mat-icon>undo</mat-icon>
    </button>
    <button mat-icon-button (click)="konvaService.redo()">
      <mat-icon>redo</mat-icon>
    </button>
    <mat-divider vertical></mat-divider>
  }
  <!-- <button mat-icon-button (click)="konvaService.center()">
    <mat-icon>control_camera</mat-icon>
  </button> -->
  <!-- <mat-divider vertical></mat-divider> -->
  <button mat-icon-button (click)="konvaService.move('left')">
    <mat-icon>west</mat-icon>
  </button>
  <button mat-icon-button (click)="konvaService.move('top')">
    <mat-icon>north</mat-icon>
  </button>
  <button mat-icon-button (click)="konvaService.move('bottom')">
    <mat-icon>south</mat-icon>
  </button>
  <button mat-icon-button (click)="konvaService.move('right')">
    <mat-icon>east</mat-icon>
  </button>

  <mat-divider vertical></mat-divider>
  <button mat-button (click)="toggleBoxes()" [class.selected]="showBoxes">
    <mat-icon>{{ showBoxes ? 'wallpaper' : 'image' }}</mat-icon>
    <span i18n>Toggle boxes</span>
  </button>

  @if (!readonlyMode) {
    <mat-divider vertical></mat-divider>
    <button mat-icon-button (click)="save()">
      <mat-icon>save</mat-icon>
    </button>
    <button mat-icon-button (click)="compute()">
      <mat-icon>send</mat-icon>
    </button>
    
    <mat-divider vertical></mat-divider>
    <button mat-button (click)="searchModel()">
      <mat-icon>search</mat-icon>
      <span i18n>Search model</span>
    </button>
    
    <button mat-button (click)="openModelCreation()">
      <mat-icon>add</mat-icon>
      <span i18n>Create model</span>
    </button>
  }

  <mat-divider></mat-divider>
</span>
@if (!readonlyMode) {
  <span class="toolbar mat-elevation-z2">
    <app-toolbar (createBox)="createBox($event)"></app-toolbar>
  </span>
}
<div class="boundary">
  <!-- @if (!readonlyMode) {
    <div class="bar-box floating-layout mat-elevation-z2" cdkDrag cdkDragBoundary=".boundary">
      <div cdkDragHandle>
        <mat-icon>drag_indicator</mat-icon>
      </div>
      <app-toolbar canToggle></app-toolbar>
    </div>
  } -->

  <div class="document panel floating-layout" cdkDrag cdkDragBoundary=".boundary">
      <div cdkDragHandle>
        <mat-icon class="small">drag_indicator</mat-icon>
      </div>
      <app-document-panel [model]="document" (documentChange)="updateDocument($event)"></app-document-panel>
  </div>

  <div class="selection panel floating-layout" cdkDrag cdkDragBoundary=".boundary">
    <div cdkDragHandle>
      <mat-icon class="small">drag_indicator</mat-icon>
    </div>
    <app-selection-panel [box]="selectedBox" (boxChange)="emitUpdatedBox($event)" (removed)="removeBox($event)"></app-selection-panel>
  </div>

  <div id="scene" (click)="onStageClick($event)"></div>
</div>



<!-- Contextual menu to show when right clic  -->
<div id="menu" #menu>
    <button mat-button id="rename">
      <mat-icon>edit</mat-icon>
      Rename</button>
    <button mat-button id="flip-to-front">
      <mat-icon>flip_to_front</mat-icon>
     To front</button>
    <button mat-button id="flip-to-back">
      <mat-icon>flip_to_back</mat-icon>
      To back
    </button>
    <!-- <button mat-button >
      <mat-icon>flip_to_front</mat-icon>
      Changer de type</button> -->
    <!-- <button mat-button id="copy">
      <mat-icon>copy</mat-icon>
      Copy
    </button>
    <button mat-button id="cut">
      <mat-icon>cut</mat-icon>
      Cut
    </button> -->
    <button mat-button id="delete">
      <mat-icon>delete</mat-icon>
      Remove
    </button>
</div>

<!-- <div id="test" #test cdkDrag cdkDragBoundary=".boundary">
  <button>
    <span>TYPE</span>
    <span>Bounding box</span>
  </button>
  <button mat-icon-button>
    <mat-icon>more_horiz</mat-icon>
  </button>
  <button mat-button id="rename">
    <mat-icon>edit</mat-icon>
    Renommer</button>
  <button mat-button id="flip-to-front">
    <mat-icon>flip_to_front</mat-icon>
    Mettre au premier plan</button>
  <button mat-button id="flip-to-back">
    <mat-icon>flip_to_back</mat-icon>
    Mettre en arrière-plan
  </button>
  <button mat-button >
    <mat-icon>flip_to_front</mat-icon>
    Changer de type</button>
  <button mat-button id="delete" color="warn">
    <mat-icon>delete</mat-icon>
    Supprimer
  </button>
</div> -->
