import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Project } from 'src/app/models/project.model';
import { environment } from 'src/environment';
import { EnvironmentService } from '../environment.service';

@Injectable({
  providedIn: 'root'
})
export class ProjectCrudService {
	private baseUrl = `${this.environment.apiUrl}/project`;

  constructor(private http: HttpClient, private environment: EnvironmentService) { }

	// Get
  getProjects(): Observable<Project[]> {
    return this.http.get<Project[]>(this.baseUrl);
  }
  getProjectById(projectId: number): Observable<Project> {
    return this.http.get<Project>(`${this.baseUrl}/${projectId}`);
  }
  // Post
  createProject(name: string, customerId: number, configIds?: number[]): Observable<Project> {
    const data = {
      name,
      customerId,
      configIds: configIds || [],
    }
    return this.http.post<Project>(this.baseUrl, data);
  }
  // Head
  checkProjectbyId(projectId: number) {
    return this.http.head(`${this.baseUrl}/${projectId}`, { observe: 'response' });
  }
  // Put
  editProject(projectId: number, project: Partial<Project>): Observable<Project> {
    return this.http.put<Project>(`${this.baseUrl}/${projectId}`, { ...project, configIds: project.configs?.map(config => config.id) });
  }
  // Delete
  deleteProject(projectId: number): Observable<any> {
    return this.http.delete(`${this.baseUrl}/${projectId}`, { responseType: 'text'});
  }
}
