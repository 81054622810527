<h2 mat-dialog-title i18n>
	Migration
</h2>
<mat-dialog-content class="mat-typography">
	<div class="header">
		<div class="start">
			<mat-checkbox [(ngModel)]="overwrite" [disabled]="started" i18n>Overwrite</mat-checkbox>
			<button mat-fab extended [disabled]="started" (click)="triggerFileInput()">
				<mat-icon>rocket_launch</mat-icon>
				<span i18n>Start migration</span>
			</button>
		</div>
		
		@if (started || finished) {
			{{ finished ? 'Done' : 'In progress...'}} ({{successStates}} successes / {{files.length}}) 
			- {{createdStates}} created - {{updatedStates}} updated - {{errorStates}} errors
			<mat-progress-bar [value]="progressionValue" mode="determinate"></mat-progress-bar>
		}
	</div>
	@if (started || finished) {
		<mat-list>
			@for (state of states; track $index) {
				<mat-list-item>
					@if (state.loading) {
						<mat-spinner matListItemIcon diameter="24" strokeWidth="3"></mat-spinner>
					} @else {
						<mat-icon matListItemIcon>{{ getIconState(state) }}</mat-icon>
					}
					<span matListItemTitle>{{ state.fileName }}</span>
					<span matListItemLine>{{ state.message }}</span>
				</mat-list-item>
			}
		</mat-list>
	}
</mat-dialog-content>
<mat-dialog-actions align="end">
	<button mat-button cdkFocusInitial mat-dialog-close i18n [disabled]="started">Close</button>
</mat-dialog-actions>

<input #fileInput type="file" multiple name="file" accept="application/json" (change)="onSelectedFiles($event)"
	style="display: none" />
