<h2 mat-dialog-title>
	<!-- <mat-icon>{{ icon }}</mat-icon> -->
	<span>Copie de {{ data.configName }}</span>
</h2>
<mat-dialog-content class="mat-typography">
	<!-- TODO: keep copy -->
	<!-- <mat-checkbox disabled [(ngModel)]="keepCopy">Garder une copie dans l'environnement initial</mat-checkbox> -->
	<p>Copier <span>{{ data.configName }}</span> vers 
		@if (data.stage) {
			<app-stage-card [stage]="data.stage"></app-stage-card>
		} @else {
			N/A
		} ?
	</p>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Annuler</button>
  <button mat-raised-button (click)="confirm()">
  Appliquer</button>
</mat-dialog-actions>
