import { Injectable } from '@angular/core';
import { Registor } from '@doclab/schema';

// Add/Remove require when creating/deleting node in schema with models
@Injectable({
  providedIn: 'root'
})
export class ConfigConstraintsService {

  constructor() { }

  // Add require when creating node in schema with models
  addNodeRequirements(node: any, nodeKey: string, constrName: string, registor: Registor<any>) {
		const foundConstrIdx = node.constraints?.findIndex((constr: any) => constr.type === constrName) ?? -1;
		if (foundConstrIdx > -1) {
			node.constraints?.[foundConstrIdx].required?.push(nodeKey);
		} else {
			node.constraints = [];
			node.constraints.push(new registor.elements[constrName].metaClass('', [nodeKey]));
		}
	}

  removeNodeRequirements(node: any, nodeKey: string, constrName: string) {
    const foundConstrIdx = node.constraints?.findIndex((constr: any) => constr.type === constrName) ?? -1;
    if (foundConstrIdx > -1) {
      const itemIdxToRemove = node.constraints[foundConstrIdx].required?.indexOf(nodeKey);
      if (itemIdxToRemove > -1) {
        node.constraints[foundConstrIdx].required.splice(itemIdxToRemove, 1);

        // Remove constraint if optonal and required are empty
        if (node.constraints[foundConstrIdx].required?.length === 0 &&
          node.constraints[foundConstrIdx].optional?.length === 0) {
            node.constraints.splice(foundConstrIdx, 1);
          }
      }
		}
  }
}
