import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

/**
 * Manage state of sidenav/mat-drawer.
 */
export class DrawerService {

  // For projects drawer and documents drawer
  private projectsDrawerToggleSubject = new BehaviorSubject<boolean>(true);
  private documentsDrawerToggleSubject = new BehaviorSubject<boolean>(true);
  private modelsDrawerToggleSubject = new BehaviorSubject<boolean>(false);

  public toggleProjectsDrawer(toggle: boolean) {
    this.projectsDrawerToggleSubject.next(toggle);
  }

  public toggleDocumentsDrawer(toggle: boolean) {
    this.documentsDrawerToggleSubject.next(toggle);
  }

  public toggleModelsDrawer(toggle: boolean) {
    this.modelsDrawerToggleSubject.next(toggle);
  }

  getProjectsDrawerState(): Observable<boolean> {
    return this.projectsDrawerToggleSubject.asObservable();
  }

  getDocumentsDrawerState(): Observable<boolean> {
    return this.documentsDrawerToggleSubject.asObservable();
  }

  getModelsDrawerState(): Observable<boolean> {
    return this.modelsDrawerToggleSubject.asObservable();
  }
}
