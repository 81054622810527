import { ElementRef, Injectable, Renderer2, RendererFactory2, inject } from '@angular/core';
import Konva from 'konva';
import { Group } from 'konva/lib/Group';
import { KonvaEventObject } from 'konva/lib/Node';
import { KonvaRenameBoxService } from './konva-rename-box.service';
import { Layer } from 'konva/lib/Layer';
import { Stage } from 'konva/lib/Stage';
import { BOX_CONFIGS } from 'src/app/models/model/model.model';
import { EditorStateService } from './editor-state.service';

@Injectable({
  providedIn: 'root'
})
export class KonvaMenuService {
	private renderer: Renderer2;

	listenerMenuHide = () => {};
	listenerMenuRename = () => {};
	listenerMenuDelete = () => {};
	listenerMenuFlipToBack = () => {};
	listenerMenuFlipToFront = () => {};
	// listenerMenuChangeType = (e: string) => {};
  
  constructor(
		private konvaRenameBox: KonvaRenameBoxService,
		private editorState: EditorStateService
	) {
		this.renderer = inject(RendererFactory2).createRenderer(null, null);
	 }

	openMenu(event: KonvaEventObject<MouseEvent>,
		layer: Layer,
		stage: Stage,
		transformer: Konva.Transformer, 
		group: Group, 
		menu: ElementRef<HTMLDivElement>) {

    event.evt.preventDefault();
    const menuElement = menu.nativeElement;
    const deleteButton = menuElement.querySelector('#delete');
    const renameButton = menuElement.querySelector('#rename');
		const flipToBackButton = menuElement.querySelector('#flip-to-back');
		const flipToFrontButton = menuElement.querySelector('#flip-to-front');
		// const changeType = menuElement.querySelector('#flip-to-front');

    this.listenerMenuDelete = this.renderer.listen(deleteButton, 'click', () => { 
			this.editorState.deleteBox(group.getAttr('key'));
		});
    this.listenerMenuRename = this.renderer.listen(renameButton, 'click', () => {
			this.konvaRenameBox.openEditionFrame(layer, stage, transformer, group);
		});
		this.listenerMenuFlipToBack = this.renderer.listen(flipToBackButton, 'click', () => {
			group.setZIndex(1);
		});
		this.listenerMenuFlipToFront = this.renderer.listen(flipToFrontButton, 'click', () => {
			group.setZIndex(2);
		});
		// TODO: Change type with context menu
		// this.listenerMenuChangeType = this.renderer.listen(changeType, 'click', (type: string) => {
		// 	const rect = group.findOne('Rect');
		// 	if (rect) {
		// 		rect.setAttrs({...BOX_CONFIGS[type]});
		// 	}
		// });

    this.renderer.setStyle(menu.nativeElement, 'display', 'flex');
    this.renderer.setStyle(menu.nativeElement, 'top', (event.evt.clientY - menuElement.offsetHeight) + 'px');
    this.renderer.setStyle(menu.nativeElement, 'left', event.evt.clientX + 'px');

		this.listenerMenuHide = this.renderer.listen(menuElement, 'click', () => {
			this.closeMenu(event, menu);
		})
  }

	closeMenu(event: KonvaEventObject<MouseEvent>, menu: ElementRef<HTMLDivElement>) {
    event.evt.preventDefault();
    
    // Reset events
    this.listenerMenuDelete();
    this.listenerMenuRename();
		this.listenerMenuHide();

    this.renderer.setStyle(menu.nativeElement, 'display', 'none');
    this.renderer.removeStyle(menu.nativeElement, 'top');
    this.renderer.removeStyle(menu.nativeElement, 'left');
  }
}
