import { Injectable } from '@angular/core';
import Konva from 'konva';
import { BehaviorSubject, distinctUntilChanged, skip, Subject, tap } from 'rxjs';
import { BoxType, Model, RawBox } from 'src/app/models/model/model.model';

@Injectable({
  providedIn: 'root',
})
export class EditorStateService {
  // * MODEL
  public modelSubject: BehaviorSubject<Model | undefined> = new BehaviorSubject<Model | undefined>(undefined);
  model$ = this.modelSubject.asObservable().pipe();

  // * IMAGE
  public imageSubject: BehaviorSubject<Konva.Image | undefined> = new BehaviorSubject<Konva.Image | undefined>(undefined);
  image$ = this.imageSubject.asObservable().pipe();

  // * SELECT BOX
  public selectBoxIdSubject: BehaviorSubject<number | undefined> = new BehaviorSubject<number | undefined>(undefined);
  selectedBoxId$ = this.selectBoxIdSubject.asObservable().pipe(
    skip(1),
    distinctUntilChanged((prev, curr) => this.areSameValues(prev, curr)),
  );

  // * CREATE BOX
  private createBoxSubject: Subject<RawBox> = new Subject<RawBox>();
  createdBox$ = this.createBoxSubject.asObservable().pipe(
    tap((newBox: RawBox) => {
      let model = this.modelSubject.getValue();
      model?.rawBoxes.push(newBox);
      // Add to model
      this.modelSubject.next(model);
    })
  );

  // * DELETE BOX
  public deleteBoxSubject: Subject<number> = new Subject<number>();
  deletedBox$ = this.deleteBoxSubject.asObservable().pipe(
    // Update Model
    tap((id: number) => {
      let model = this.modelSubject.getValue();
      if (id && model) {
        model.rawBoxes.splice(id, 1);
        this.modelSubject.next(model);
        console.warn(id, model.rawBoxes)
        // Update selection
        if (this.getSelectedBoxId() === id) {
          // this.selectBoxIdSubject.next(undefined);
        }
      }
    })
  );

  // * UPDATE BOX
  public updateBoxSubject: BehaviorSubject<RawBox | undefined> = new BehaviorSubject<RawBox | undefined>(undefined);
  updatedBox$ = this.updateBoxSubject.asObservable().pipe(
    distinctUntilChanged((prev, curr) => this.areSameValues(prev, curr)),
    // Update Model
    tap((box: RawBox | undefined) => {
      let model = this.modelSubject.getValue();
      if (box && model) {
        model.rawBoxes[box.key] = box;
        this.modelSubject.next(model);
      }
    })
  );

  deleteBox(key: number) {
    this.deleteBoxSubject.next(key);
  }

  createDefaultBox(type: BoxType, image: Konva.Image) {
    return {
      key: this.generateKey(),
      x: 0,
      y: 0,
      width: image.width() / 3,
      height: image.height() / 3,
      name: type,
      type: type
    }
  }

  generateKey(): number {
    const model = this.modelSubject.getValue();
    if (!model) return -1
    return Math.max(...model?.rawBoxes.map((box: RawBox) => box.key)) + 1;
  }

  getImage() {
    return this.imageSubject.getValue();
  }

  getModel() {
    return this.modelSubject.getValue();
  }

  getSelectedBoxId() {
    return this.selectBoxIdSubject.getValue();
  }

  areSameValues(prev: any, curr: any) {
    console.warn(prev, curr)
    return JSON.stringify(prev) === JSON.stringify(curr)
  }

  emitCreationBox(type: BoxType | string) {
    const model = this.modelSubject.getValue();
    const image = this.getImage();
    if (model && image) {
      const defaultBox = this.createDefaultBox(type as BoxType, image);
      this.createBoxSubject.next(defaultBox);
      this.selectBoxIdSubject.next(defaultBox.key);
    }
  }
}
