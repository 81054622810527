import { Component, ElementRef, ViewChild } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { ModelCrudService } from 'src/app/services/crud/model/model-crud.service';

interface MigrationState {
  fileName: string;
  loading?: boolean;
  success?: boolean;
  created?: boolean;
  updated?: boolean;
  error?: boolean;
  message?: string;
}

@Component({
  selector: 'app-dialog-migration-loader-dialog',
  templateUrl: './dialog-migration-loader-dialog.component.html',
  styleUrl: './dialog-migration-loader-dialog.component.scss'
})
export class DialogMigrationLoaderDialogComponent {
  @ViewChild('fileInput', { static: true })
  fileInput!: ElementRef<HTMLInputElement>;
  files: File[] = [];

  states: MigrationState[] = [];
  started: boolean = false;
  finished: boolean = false;
  overwrite: boolean = true;

  get createdStates() {
    return this.states.filter(state => state.created).length;
  }

  get updatedStates() {
    return this.states.filter(state => state.updated).length;
  }

  get errorStates() {
    return this.states.filter(state => state.error).length;
  }

  get successStates() {
    return this.states.filter(state => state.success).length;
  }

  get progressionValue() {
    return Math.round((this.successStates / this.files.length) * 100);
  }

  constructor(private modelApi: ModelCrudService) 
  {}

  startMigration(files: File[]) {
    this.files = files;
    this.states = [];
    this.started = true;
    const filePromises = files.map((file, index) => this.migrateFile(file, index));

    Promise.allSettled(filePromises).then(() => {
      this.started = false;
      this.finished = true;
    });
  }

  async migrateFile(file: File, index: number): Promise<any> {
    this.states[index] = {
      fileName: file.name, 
      loading: true,
      error: false, 
      success: false, 
      updated: false, 
      created: false,
      message: ''
    };

    try {
      const response = await firstValueFrom(this.modelApi.migrate(file, this.overwrite));
      switch (response) {
        case 200:
          this.states[index].updated = true;
          this.states[index].message = 'Updated';
          break;
        case 201:
          this.states[index].created = true;
          this.states[index].message = 'Created';
          break;
      }
      this.states[index].loading = false;
      this.states[index].success = true;
      // Updated
      if (response === 200) {
        this.states[index].updated = true;
      }
      // Created
      if (response === 201) {
        this.states[index].created = true;
      }
      return response;
    } catch (error) {     
      this.states[index].loading = false;
      this.states[index].error = true;
      this.states[index].message = 'Migration error';
      return Promise.reject();
    }
  }

  triggerFileInput() {
    // Reset the value property to allow select multiple times the same file
			this.fileInput.nativeElement.value = '';
			// Load/Prepare files and then start
			this.fileInput.nativeElement.click();
  }

  async onSelectedFiles(event: any) {
		const fileList = event?.target?.files as FileList;
    const files: File[] = this.getAllJSONFiles(fileList) ?? [];
		if (files.length === 0) {
			return;
		}

    this.startMigration(files);
	}

  getAllJSONFiles(fileList: FileList): File[] | null {
    const jsonFiles: File[] = [];

    for (let i = 0; i < fileList.length; i++) {
        const file = fileList[i];
        if (file.type === "application/json") {
            jsonFiles.push(file);
        } else {
            return null;
        }
    }
    return jsonFiles;
  }

  getIconState(state: MigrationState) {
    if (state.created) {
      return 'task';
    }
    if (state.updated) {
      return 'edit_document';
    }
    if (state.error) {
      return 'close';
    }
    return
  }
}
