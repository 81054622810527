<h2 mat-dialog-title> 
	<!-- <mat-icon>tune</mat-icon> -->
	<span i18n>Confirm</span>
</h2>
<mat-dialog-content class="mat-typography">
    <span i18n>{{ data.message }}</span>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-button (click)="choice(false)" cdkFocusInitial>
        <mat-icon>close</mat-icon>
        <span i18n>No</span>
    </button>
    <button mat-button (click)="choice(true)" >
        <mat-icon>check</mat-icon>
        <span i18n>Yes</span>
    </button>
</mat-dialog-actions>
