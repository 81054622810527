import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Model } from 'src/app/models/model/model.model';

@Component({
  selector: 'app-document-panel',
  templateUrl: './document-panel.component.html',
  styleUrl: './document-panel.component.scss'
})
export class DocumentPanelComponent {
  @Input() model?: Model;
  @Output() documentChange = new EventEmitter<Model>();
}
