import { Injectable } from '@angular/core';
import { Layer } from 'konva/lib/Layer';

@Injectable({
  providedIn: 'root'
})
export class KonvaHistoryService {
  layerStates: Layer[] = [];
  layerStateSelection: number = 0;

  constructor() { }

  initHistory() {
    this.layerStates = [];
    this.layerStateSelection = 0;
  }

  public save(layer: Layer) {
    const layersLength: number = this.layerStates.push(layer.clone());
    this.layerStateSelection = layersLength - 1;
    // console.warn(this.layerStates)
  }

  getPreviousState() {
    this.layerStateSelection--;
    // console.warn(this.layerStateSelection, this.layerStates[this.layerStateSelection]);
    return this.layerStates[this.layerStateSelection];
  }

  getNextState(): Layer {
    this.layerStateSelection++;
    return this.layerStates[this.layerStateSelection];
  }

  hasNextState(): boolean {
    return this.layerStateSelection < this.layerStates.length - 1;
  }

  hasPreviousState(): boolean {
    return this.layerStateSelection > 0 ;
  }
}
