@if (loading) {
  <mat-progress-bar mode="query"></mat-progress-bar>
}
<h2 mat-dialog-title i18n>
  {{ data.title ?? 'Models'}}
</h2>

<mat-dialog-content>
  <div class="dialog-content">
    @if (data.sortableList) {
      <app-document-model-list
      [class.hidden]="hiddenList"
      [selectedModels]="globalSelectedModels"
      dynamicSelection
      showModels
      [models]="models" 
      (listChange)="defineData($event)">
    </app-document-model-list>
    }

    <app-document-models
    dynamicSelection
    removePaginator
    [selectedModels]="selectedModels"
    [models]="data.sortableList ? detailedModels : models"
    [singleSelection]="data.singleSelection"
    (addedModels)="addModels($event)"
    (removedModels)="removeModels($event)"
    (listChange)="changeModels($event)">
    </app-document-models>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  @if (data.sortableList) {
    <button mat-button class="toggle-list" (click)="hiddenList = !hiddenList" i18n>Toggle list</button>
  }

  <!-- @if (!data.disableClose) {
    <button mat-button mat-dialog-close i18n>Cancel</button>
  } -->
  
  @if (data.createModelOption) {
    <button mat-button (click)="createModel()" i18n>
      <mat-icon>add</mat-icon>
      Create new model
    </button>
  }
  @if (data.showDetailedModels) {
    <button mat-button i18n (click)="showSelectedModels()" 
    [disabled]="globalSelectedModels.length === 0">Show selected models ({{globalSelectedModels.length}})</button>
  }
  <button mat-raised-button cdkFocusInitial (click)="confirm()" i18n>Confirm</button>
</mat-dialog-actions>
