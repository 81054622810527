<mat-accordion>
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title i18n>
				<mat-icon>description</mat-icon>
				@if (model) {
					Document
				} @else {
					Pending...
				}				
			</mat-panel-title>
    </mat-expansion-panel-header>

		<app-model-form [model]="model" (modelChange)="documentChange"></app-model-form>
  </mat-expansion-panel>
  <!-- <mat-expansion-panel #imagePanel (opened)="updateImages()"> -->
		<mat-expansion-panel>
			<app-image-panel [images]="model?.images"></app-image-panel>
    <mat-expansion-panel-header>
      <mat-panel-title i18n> Images </mat-panel-title>
    </mat-expansion-panel-header>

  </mat-expansion-panel>
</mat-accordion>