import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RawSchema } from 'src/app/models/schema/schema.model';
import { EnvironmentService } from '../../environment.service';

@Injectable({
  providedIn: 'root'
})
export class SchemaCrudService {
  private baseUrl = `${this.environment.apiUrl}/schema`;

  constructor(private http: HttpClient, private environment: EnvironmentService) { }

  // Get
  getSchemas(): Observable<any[]> {
    return this.http.get<any[]>(this.baseUrl);
  }
  getSchemaById(schemaId: number): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}/${schemaId}`);
  }

  getSchemasByGroup(groupId: number): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}/${groupId}`);
  }

  getSchemasByProject(projectId: number): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}/${projectId}`);
  }

  getUserByConfig(configId: number): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}/${configId}`);
  }

  // Set
  assignSchemaToGroup(schemaId: number, groupId: number): Observable<any> {
    return this.http.put<any>(`${this.baseUrl}/${schemaId}`, groupId);
  }

  assignSchemaToProject(schemaId: number, projectId: number): Observable<any> {
    return this.http.put<any>(`${this.baseUrl}/${schemaId}`, projectId);
  }

  assignSchemaToConfig(schemaId: number, configId: number): Observable<any> {
    return this.http.put<any>(`${this.baseUrl}/${schemaId}`, configId);
  }

  editSchema(schemaId: string, rawSchema: RawSchema): Observable<any> {
    return this.http.put<any>(`${this.baseUrl}/${schemaId}`, { body: rawSchema.body });
  }

  createSchema(rawSchema: RawSchema): Observable<{ id: string }> {
    return this.http.post<{ id: string }>(`${this.baseUrl}`, { body: rawSchema.body });
  }

  // Delete
  deleteSchemaById(schemaId: number): Observable<any> {
    return this.http.delete<any>(`${this.baseUrl}/${schemaId}`);
  }

  deleteSchemasByGroup(groupId: number): Observable<any> {
    return this.http.delete<any>(`${this.baseUrl}/${groupId}`);
  }

  deleteSchemasByProject(projectId: number): Observable<any> {
    return this.http.delete<any>(`${this.baseUrl}/${projectId}`);
  }

  deleteSchemasByConfig(configId: number): Observable<any> {
    return this.http.delete<any>(`${this.baseUrl}/${configId}`);
  }
}
