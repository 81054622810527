import { Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { firstValueFrom } from 'rxjs';
import { Customer } from 'src/app/models/customer.model';
import { getNodeIcon } from 'src/app/models/global.model';
import { ProjectCrudService } from 'src/app/services/crud/project-crud.service';
import { UserService } from 'src/app/services/user.service';

export interface DialogDuplicateProjectData {
  projectName: string,
  projectId: number
}

@Component({
  selector: 'app-dialog-duplicate-project-dialog',
  templateUrl: './dialog-duplicate-project-dialog.component.html',
  styleUrl: './dialog-duplicate-project-dialog.component.scss'
})
export class DialogDuplicateProjectDialogComponent implements OnInit {
  customersCtrl = new FormControl<Customer[]>([]);
  userCustomers: Customer[] = [];

  icon: string = '';

  duplicateRelatedConfigs: boolean = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogDuplicateProjectData,
  private matDialog: MatDialogRef<DialogDuplicateProjectDialogComponent>,
  private userService: UserService,
  private projectApi: ProjectCrudService,
  private snackbar: MatSnackBar) 
  {
    this.icon = getNodeIcon('project');
  }

  async ngOnInit() {
    try {
      this.userCustomers = await this.userService.getUserCustomers();
    } catch (error) {
      this.snackbar.open((error as Error).message);
    }
  } 

  async confirm(name: string) {
    try {
      if (this.customersCtrl.value) {
        for await (const customer of this.customersCtrl.value) {
          await firstValueFrom(this.projectApi.createProject(name, customer.id));
        }
        this.snackbar.open(`Projet ${name} dupliqué.`, 'Ok', { duration: 3000 });
      }
      this.matDialog.close(true);
    } catch (error) {
      this.snackbar.open((error as Error).message);
    }
  }
}
