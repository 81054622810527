import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, Subject, takeUntil, throwError } from 'rxjs';
import { Config } from 'src/app/models/config.model';
import { CANCELED_REQUEST } from 'src/app/models/database.model';
import { EnvironmentService } from '../environment.service';

export interface SaveConfigData {
  config?: Config,
  configFragments?: { configId: number, schemaId: string, modelIds: string[] }
}

@Injectable({
  providedIn: 'root'
})
export class ConfigCrudService {
  private cancelPreviousRequest$ = new Subject<void>();
  private baseUrl = `${this.environment.apiUrl}/config`;

  constructor(private http: HttpClient, private environment: EnvironmentService) { }

	// Get
  getConfigs(): Observable<Config[]> {
    return this.http.get<Config[]>(this.baseUrl);
  }
  getConfigById(configId: number): Observable<Config> {
    // Cancel the previous request if exists during navigation routes
    this.cancelPreviousRequest$.next();

    return this.http.get<Config>(`${this.baseUrl}/${configId}`)
    .pipe(
      takeUntil(this.cancelPreviousRequest$),
      catchError(err => {
        if (err.name === 'HttpErrorResponse' && err.status === 0) {
          // return an empty observable to signify a canceled request without triggering an error
          return throwError(() => CANCELED_REQUEST);
        }
        return throwError(() => err);
      })
    );
  }
  // Post
  createConfig(name: string, stageId?: number): Observable<Config> {
    const projectIds: number[] = [];
    return this.http.post<Config>(this.baseUrl, { name, projectIds, stageId });
  }
  attachProject(configId: number, projectId: number) {
    return this.http.post<Config>(`${this.baseUrl}/${configId}/attachProject`, { projectId });
  }
  detachProject(configId: number, projectId: number) {
    return this.http.post<Config>(`${this.baseUrl}/${configId}/detachProject`, { projectId });
  }
  attachStage(configId: number, stageId: number) {
    return this.http.post<Config>(`${this.baseUrl}/${configId}/attachStage`, { stageId });
  }
  detachStage(configId: number) {
    return this.http.post<Config>(`${this.baseUrl}/${configId}/detachStage`, {});
  }
  // Put
  editConfig(configId: number, config: Partial<Config>): Observable<Config> {
    return this.http.put<Config>(`${this.baseUrl}/${configId}`, { ...config });
  }
  saveConfig(data?: SaveConfigData) {
    const configId = data?.config?.id ?? data?.configFragments?.configId;
    const schemaId = data?.config?.schemaId ?? data?.configFragments?.schemaId;
    const modelIds = data?.config?.models?.map(m => m.id) ?? data?.configFragments?.modelIds;
    return this.http.put(`${this.baseUrl}/${configId}/save`, { schemaId, modelIds }, { responseType: 'text' });
  }
  deployConfig(configId: number) {
    return this.http.put(`${this.baseUrl}/${configId}/deploy`, {}, { responseType: 'text' });
  }
  undeployConfig(configId: number) {
    return this.http.put(`${this.baseUrl}/${configId}/undeploy`, {}, { responseType: 'text' });
  }
  // Head
  checkConfigById(configId: number) {
    return this.http.head(`${this.baseUrl}/${configId}`, { observe: 'response' });
  }
  // Delete
  deleteConfig(configId: number): Observable<any> {
    return this.http.delete(`${this.baseUrl}/${configId}`, { responseType: 'text' });
  }
}
