
	<h2 mat-dialog-title>
		<span>Sauvegarde de {{ data.configName }}</span>
	</h2>
	<mat-dialog-content class="mat-typography">
		@if (saving) {
			<mat-spinner></mat-spinner>
		} @else {
			@if (!data.skipConfirmation) {
				@if (data.warnBeforeLeaving) {
					<span>Vous êtes sur le point de quitter la configuration sans appliquer vos modifications.</span>
				}
				<p>Sauvegarder <span>{{ data.configName }}</span> dans 
					@if (data.stage) {
						<app-stage-card [stage]="data.stage"></app-stage-card>
					} @else {
						N/A
					} ?
				</p>
			}	
		}
	</mat-dialog-content>
	@if (!data.skipConfirmation) {
		<mat-dialog-actions align="end">
			<button mat-button mat-dialog-close [disabled]="saving">Annuler</button>
			<button mat-button [mat-dialog-close]="true" [disabled]="saving">Continuer</button>
			<button mat-raised-button [disabled]="saving" (click)="save()">
			Appliquer</button>
		</mat-dialog-actions>
	}