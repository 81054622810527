import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EnvironmentService {
  apiUrl: string = '';
  name: string = '';

  constructor() { }

  initBaseUrl() {
    if (window.location) {
      if (window.location.origin.includes('localhost') ||
          window.location.origin.includes('127.0.0.1')) {
        this.apiUrl = 'https://bff.dev.wizidee.com';
        // this.apiUrl = 'http://localhost:3000';
      } else {
        this.name = this.getNameFromUrl();
        let managerUrl = window.location.origin.split('.');
        managerUrl[0] = 'bff';
        this.apiUrl = 'https://' + managerUrl.join('.');
      }
    }
  }

  getNameFromUrl() {
    const url = window.location.origin;
    const domain = new URL(url).hostname;
    const parts = domain.split('.');

    return parts.length > 1 ? parts[1] : '';
  }
}
