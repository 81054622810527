import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { firstValueFrom } from 'rxjs';
import { getNodeIcon } from 'src/app/models/global.model';
import { ConfigCrudService } from 'src/app/services/crud/config-crud.service';

export interface DialogRenameConfigData {
  nodeId: number;
  nodeName: string;
}

@Component({
  selector: 'app-dialog-rename-config-dialog',
  templateUrl: './dialog-rename-config-dialog.component.html',
  styleUrl: './dialog-rename-config-dialog.component.scss'
})
export class DialogRenameConfigDialogComponent {
  icon: string = '';

  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogRenameConfigData,
  private matDialog: MatDialogRef<DialogRenameConfigDialogComponent>,
  private configApi: ConfigCrudService,
  private snackbar: MatSnackBar) 
  {
    this.icon = getNodeIcon('config');
  }

  async confirm(newName: string) {
    try {
      let config = await firstValueFrom(this.configApi.getConfigById(this.data.nodeId));
      if (config) {
        let renamedConfig = await firstValueFrom(this.configApi.editConfig(
          this.data.nodeId, { ...config, name: newName }
        ));
        this.snackbar.open(`Configuration ${this.data.nodeName} renommé en ${renamedConfig.name}.`, 'Ok');
        this.matDialog.close(renamedConfig);
      }
    } catch (error) {
      this.snackbar.open((error as Error).message);
    }
  }
}
