<h2 mat-dialog-title>
	<!-- <mat-icon>{{ icon }}</mat-icon> -->
	Duplication de {{ data.configName }}
</h2>
<mat-dialog-content class="mat-typography">
	<!-- Project list to assign -->
	<mat-form-field subscriptSizing="dynamic" floatLabel="always">
		<mat-label>Projets</mat-label>
		<mat-select [formControl]="projectsCtrl" multiple>
			<mat-select-trigger>
				{{projectsCtrl.value?.[0]?.name || ''}}
				@if ((projectsCtrl.value?.length || 0) > 1) {
					<span class="example-additional-selection">
						(+{{(projectsCtrl.value?.length || 0) - 1}} {{projectsCtrl.value?.length === 2 ? 'other' : 'autres'}})
					</span>
				}
			</mat-select-trigger>
			@for (project of projects; track project.id) {
				<!-- TODO: Add customer name for admin -->
		<mat-option [value]="project">{{project.name}}</mat-option>
	}
		</mat-select>
	</mat-form-field>

	<!-- New name (or not) -->
  <mat-form-field subscriptSizing="dynamic" floatLabel="always">
		<mat-label>Nom de la configuration</mat-label>
		<input matInput #input [value]="data.configName">
	</mat-form-field>

<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Annuler</button>
  <button mat-raised-button (click)="confirm(input.value)"
  [disabled]="!input.value">
  Appliquer</button>
</mat-dialog-actions>
