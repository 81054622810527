<h2 mat-dialog-title i18n>Add users</h2>
<mat-dialog-content class="mat-typography">
  <mat-form-field subscriptSizing="dynamic">
		<mat-label i18n>Filter</mat-label>
		<input
      matInput
      (keyup)="applyFilter($event)"
      placeholder="Ex. Mia"
      i18n-placeholder
      #input>
    <mat-icon matPrefix>search</mat-icon>
	</mat-form-field>

  <mat-selection-list #userList>
    @for (user of filteredUsers; track user.id) {
      <mat-list-option [value]="user.id">{{user.name}}</mat-list-option>
    }
  </mat-selection-list>
  <p><span i18n>Selected profiles</span> : {{userList.selectedOptions.selected.length}}</p>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close i18n>Cancel</button>
  <button
    mat-raised-button
    cdkFocusInitial
    (click)="confirm()"
    [disabled]="userList.selectedOptions.selected.length === 0">
    <mat-icon>add</mat-icon>
    <span i18n>Add</span>
  </button>
</mat-dialog-actions>
