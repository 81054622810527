import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { firstValueFrom } from 'rxjs';
import { CustomerCrudService } from 'src/app/services/crud/customer-crud.service';

export interface DialogDeleteCustomerData {
  nodeName: string;
  nodeId: number;
}

@Component({
  selector: 'app-dialog-delete-customer-dialog',
  templateUrl: './dialog-delete-customer-dialog.component.html',
  styleUrl: './dialog-delete-customer-dialog.component.scss'
})
export class DialogDeleteCustomerDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogDeleteCustomerData,
  private matDialog: MatDialogRef<DialogDeleteCustomerDialogComponent>,
  private customerApi: CustomerCrudService,
  private snackbar: MatSnackBar) 
  {}

  async confirm() {
    try {
      const deletedCustomer = await firstValueFrom(this.customerApi.deleteCustomer(this.data.nodeId));
      this.snackbar.open(`Client ${this.data.nodeName} supprimé.`, 'Ok', { duration: 3000 });
      this.matDialog.close(deletedCustomer);
    } catch (error) {
      this.snackbar.open((error as Error).message);
    }
  }
}
