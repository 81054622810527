import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface DialogConfirmChoiceData {
  message?: string;
}

@Component({
  selector: 'app-dialog-confirm-choice-dialog',
  templateUrl: './dialog-confirm-choice-dialog.component.html',
  styleUrl: './dialog-confirm-choice-dialog.component.scss'
})
export class DialogConfirmChoiceDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogConfirmChoiceData,
    private matDialog: MatDialogRef<DialogConfirmChoiceDialogComponent>
  ) {}

  choice(response: boolean) {
    this.matDialog.close(response)
  }

}
