import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { firstValueFrom } from 'rxjs';
import { getNodeIcon } from 'src/app/models/global.model';
import { ProjectCrudService } from 'src/app/services/crud/project-crud.service';

export interface DialogRenameProjectData {
  nodeId: number;
  nodeName: string;
}

@Component({
  selector: 'app-dialog-rename-project-dialog',
  templateUrl: './dialog-rename-project-dialog.component.html',
  styleUrl: './dialog-rename-project-dialog.component.scss'
})
export class DialogRenameProjectDialogComponent {
  icon: string = '';

  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogRenameProjectData,
  private matDialog: MatDialogRef<DialogRenameProjectDialogComponent>,
  private projectApi: ProjectCrudService,
  private snackbar: MatSnackBar) 
  {
    this.icon = getNodeIcon('project');
  }

  async confirm(newName: string) {
    try {
      let project = await firstValueFrom(this.projectApi.getProjectById(this.data.nodeId));
      if (project) {
        let renamedProject = await firstValueFrom(this.projectApi.editProject(
          this.data.nodeId, { ...project, name: newName }
        ));
        this.snackbar.open(`Projet ${this.data.nodeName} renommé en ${renamedProject.name}.`, 'Ok');
        this.matDialog.close(renamedProject);
      }
    } catch (error) {
      this.snackbar.open((error as Error).message);
    }
  }
}
