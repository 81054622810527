import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { Model } from 'src/app/models/model/model.model';

interface ModelRow {
  position: number;
  id: string;
  source: Model;
  name: string;
  type?: string;
  category?: string;
  language?: string;
  page: string;
  image?: string;
  priority?: number;

  loadingImage?: boolean;

  createdAt: string,
  updatedAt: string
}

interface MergedRow {
  type: string,
  models: Model[],
  selectedModels: number;
  selected?: boolean
}

interface GroupedRows {
  [category: string]: MergedRow[];
}

@Component({
  selector: 'app-document-model-list',
  templateUrl: './document-model-list.component.html',
  styleUrl: './document-model-list.component.scss'
})
export class DocumentModelListComponent implements OnChanges {
  modelRows: ModelRow[] = [];
  hiddenGroups: string[] = [];
  sortName: string = '';
  sortedList: GroupedRows = {};

  loading: boolean = false;

  @Input() models: Model[] = [];
  @Input() selectedModels: Model[] = [];

  // Emit selection for each selection change without confirmation
  @Input({transform: coerceBooleanProperty}) dynamicSelection: boolean = false;
  @Input({transform: coerceBooleanProperty}) showModels: boolean = false;

  @Output() listChange = new EventEmitter<Model[]>();

  async ngOnChanges(changes: SimpleChanges) {
    if (changes['models']) {
      await this.loadModels();
      this.selectModels();
    }

    if (changes['selectedModels']) {
      this.selectModels();
    }
  }

  async loadModels() {
    this.modelRows = this.convertToRows(this.models);
    this.sortedList = this.groupItemsByKey('Category', 'category');
  }

  selectModels() {
    for (const listKey in this.sortedList) {
      const mergedRows = this.sortedList[listKey];
      for (const mergedRow of mergedRows) {
        mergedRow.selectedModels = 0;
        for (const model of mergedRow.models) {
          if (this.selectedModels.includes(model)) {
            mergedRow.selectedModels++;
          }
        }
      }
    }
  }

  convertToRows(models: Model[]): ModelRow[] {
    const modelRows: ModelRow[] = [];
    models.forEach((model, modelIdx) => {
       const newModelRow: ModelRow = {
        position: modelIdx,
        id: model.id.toString(),
        source: model,
        name: model.name?.toString(),
        page: model.page?.toString(),
        type: model.docType?.name,
        category: model.docType?.category?.name,
        language: model.language?.code,
        // Image will load next time
        loadingImage: true,
        createdAt: model.createdAt!,
        updatedAt: model.updatedAt!,
       };
       modelRows.push(newModelRow);
    });

    return modelRows;
  }

  groupItemsByKey(sortName: string, modelKey: keyof ModelRow) {
    this.sortName = sortName;
    const groupedItems: GroupedRows = {};
    this.modelRows.forEach(modelRow => {
      let rowValue: any = modelRow[modelKey];
      if (rowValue) {
        if (!groupedItems[rowValue]) {
          groupedItems[rowValue] = [];
        }
        const foundIndex = this.findSameTypeIndexModelRow(groupedItems, modelRow, rowValue);
        if (foundIndex > -1) {
          groupedItems[rowValue][foundIndex].models.push(modelRow.source);
        } else {
          groupedItems[rowValue].push({type: modelRow.type!, models: [modelRow.source], selectedModels: 0});
        }
      }
    });
    return groupedItems;
  }

  groupItemsBySelection(sortName: string) {
    this.sortName = sortName;
    let groupedItems: GroupedRows = this.sortedList;
    for (const listKey in this.sortedList) {
      groupedItems[listKey] = groupedItems[listKey].filter(mergedRow => { 
        return mergedRow.selectedModels && mergedRow.selectedModels > 0 
      });
    }
    return groupedItems;
  }

  toggleCheckAll(headerName: string, isChecked: boolean) {
    this.sortedList[headerName].forEach(item => item.selected = isChecked);
    if (this.dynamicSelection) {
      this.listChange.emit(this.getModelsFromSelection());
    }
  }

  toggleCheckRow(row: MergedRow) {
    row.selected = !row.selected;
    if (this.dynamicSelection) {
      this.listChange.emit(this.getModelsFromSelection());
    }
  }

  allChecked(headerName: string): boolean {
    return this.sortedList[headerName].every(item => item.selected);
  }

  findSameTypeIndexModelRow(rows: {[key: string]: MergedRow[]}, modelRow: ModelRow, modelKey: keyof ModelRow) {
    return rows[modelKey].findIndex(model => model.type === modelRow.type) ?? -1;
  }

  toggleGroup(groupName: string) {
    if (this.hiddenGroups.includes(groupName)) {
      const index = this.hiddenGroups.findIndex(group => group === groupName);
      this.hiddenGroups.splice(index, 1);
    } else {
      this.hiddenGroups.push(groupName);
    }
  }

  getModelsFromSelection() {
    let models: Model[] = [];
    for (const key in this.sortedList) {
      let mergedRows = this.sortedList[key];
      for (const mergeRow of mergedRows) {
        if (mergeRow.selected) {
          models.push(...mergeRow.models);
        }
      }
    }
    return models;
  }
}
