import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Customer } from 'src/app/models/customer.model';
import { EnvironmentService } from '../environment.service';

export interface FoodNode {
  name: string;
  children?: FoodNode[];
}

@Injectable({
  providedIn: 'root'
})
export class CustomerCrudService {
	private baseUrl = `${this.environment.apiUrl}/customer`;

  constructor(private http: HttpClient, private environment: EnvironmentService) { }
	// Get
  getCustomers(): Observable<Customer[]> {
    return this.http.get<Customer[]>(this.baseUrl);
  }
  getCustomerById(customerId: number): Observable<Customer> {
    return this.http.get<Customer>(`${this.baseUrl}/${customerId}`);
  }
  // Post
  createCustomer(name: string, domain?: string): Observable<Customer> {
    return this.http.post<Customer>(this.baseUrl, { name, tools: [], domain });
  }
  attachUserInCustomer(customerId: number, userId: number) {
    return this.http.post(`${this.baseUrl}/${customerId}/attachUser`, { userId }, { responseType: 'text' });
  }
  detachUserInCustomer(customerId: number, userId: number) {
    return this.http.post(`${this.baseUrl}/${customerId}/detachUser`, { userId }, { responseType: 'text' });
  }
  // Head 
  checkCustomerById(customerId: number) {
    return this.http.head(`${this.baseUrl}/${customerId}`, { observe: 'response' });
  }
  // Put
  editCustomer(customerId: number, customer: Partial<Customer>): Observable<any> {
    return this.http.put<Customer>(`${this.baseUrl}/${customerId}`, { ...customer, tools: [] });
  }
  // Delete
  deleteCustomer(customerId: number): Observable<any> {
    return this.http.delete(`${this.baseUrl}/${customerId}`, { responseType: 'text'});
  }
	// getData(): Observable<TreeNode[]> {
  //   return of(TREE_DATA);
  // }
}
