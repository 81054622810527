import { Injectable } from '@angular/core';
import { io, Socket } from "socket.io-client";
import { AuthService } from './auth.service';
import { EnvironmentService } from './environment.service';

@Injectable({
  providedIn: 'root'
})

/**
 * Manage socket endpoints.
 */
export class SocketService {
  public socket: Socket = io(`${this.environment.apiUrl}`, 
    { reconnectionDelay: 6000, 
      reconnectionDelayMax: 6000, 
      reconnection: true,
      reconnectionAttempts: 10
    }
  );

  constructor(private authService: AuthService, private environment: EnvironmentService) {
    this.socket.on('connect', () => {
      this.join(this.authService.bearerToken);
    });
  }

  public join(jwt: any) {
    this.socket?.emit('join', {
      jwt: jwt
    }, () => {
      this.socket.on('join', () => {
        console.warn('joined');
      })
    });
  }

  public disconnect() {
    this.socket?.disconnect();
  }
}
