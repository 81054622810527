import { ElementRef, Injectable, Renderer2, RendererFactory2, inject } from '@angular/core';
import Konva from 'konva';
import { RectConfig } from 'konva/lib/shapes/Rect';
import { KonvaEventService } from './konva-event.service';
import { KonvaHistoryService } from './konva-history.service';
import { BoxType, BOX_CONFIGS, Model, RawBox } from 'src/app/models/model/model.model';
import { KonvaMenuService } from './konva-menu.service';
import { LEFT_CLICK } from 'src/app/models/konva.model';
import { KonvaRenameBoxService } from './konva-rename-box.service';
import { EditorStateService } from './editor-state.service';

export type CropEventType = 'start' | 'stop' | 'doing' | 'done' | 'cancel' | 'ok' | 'ko' | 'none' | 'dragStart' | 'dragEnd';
export type DirectionType = 'right' | 'left' | 'top' | 'bottom';
export type ZoomType = 'fitToSize' | 'fitToHeight' | 'fitToWidth' | 'oneToOne' | 'zoomIn' | 'zoomOut' | 'none';
export type RotationType = 'cw' | 'ccw';
export declare type GravityType = 'NW' | 'NC' | 'NE' | 'CW' | 'CC' | 'CE' | 'SW' | 'SC' | 'SE';

interface ModelData {
  model: Model,
  image: string
}

@Injectable()
export class KonvaService {
  model!: Model;
  stageContainer!: ElementRef;
  stage!: Konva.Stage;
  layer!: Konva.Layer;
  transformer!: Konva.Transformer;
  image!: Konva.Image;

  // Options on selection (right click)
  menu!: ElementRef;
  activeGuidLines: boolean = true;

  private renderer: Renderer2;
  readonly scaleBy = 1.2;
  readonly moveBy = 200

  constructor(
    private konvaEvent: KonvaEventService,
    private konvaHistory: KonvaHistoryService,
    private konvaMenu: KonvaMenuService,
    private konvaRenameBox: KonvaRenameBoxService,
    private editorState: EditorStateService) {
    this.renderer = inject(RendererFactory2).createRenderer(null, null);

    // * Add
    this.editorState.createdBox$.subscribe((rawBox: RawBox) => {
      this.createBox(rawBox.type, {rawBox});
    });
    // * Select
    this.editorState.selectedBoxId$.subscribe((id: number | undefined) => {
      this.selectBox(id);
    });
    // * Remove
    this.editorState.deletedBox$.subscribe((id: number | undefined) => {
      if (id) this.removeBox(id);
    });
    // * Update
    this.editorState.updatedBox$.subscribe((box: RawBox | undefined) => {
      if (box) this.updateBox(box);
    });
   }

  async initStage(containerId: string, menu: ElementRef, modelData?: ModelData) {
    if (this.stage) this.stage.destroy();
    this.menu = menu;

    this.stage = new Konva.Stage({
      container: containerId,
      width: window.innerWidth,
      height: window.innerHeight,
      draggable: true
    });

    // this.stageContainer = this.renderer.selectRootElement(containerId, true);

    this.stage.on('wheel', (event) => {
      this.konvaEvent.toggleZoom(event, this.stage, this.scaleBy);
    });

    this.stage.on('click', (event) => {
      if (event.evt.button === LEFT_CLICK) {
        this.konvaRenameBox.closeEditionFrame(this.stage);
        this.konvaMenu.closeMenu(event, this.menu);
      }
    })

    this.layer = new Konva.Layer();
    this.stage.add(this.layer);

    if (modelData) {
      await this.generateModel(modelData)
    }
   
    this.transformer = new Konva.Transformer({
      borderStrokeWidth: 4, 
      borderStroke: '#1E90FF',
      anchorSize: 10,
      rotateEnabled: false,
      anchorStroke: 'gray',
      anchorStrokeWidth: 1,
      ignoreStroke: true
    });
    this.layer.add(this.transformer);
    this.transformer.nodes([]);

    this.transformer.on('transform', () => {
      this.layer.batchDraw();
    });
    this.konvaHistory.save(this.layer);
  }

  private getRectConfigByType(type: BoxType | string): Partial<RectConfig> {
    return BOX_CONFIGS[type] || BOX_CONFIGS['default'];
  }
  
  public createBox(type: BoxType | string, options?: {rawBox: RawBox, disableUndoRedo?: boolean }) {
    const group = new Konva.Group({
      ...this.editorState.createDefaultBox(type as BoxType, this.image),
      box: true
    });
    if (options?.rawBox.type === 'bbx') {
      group.setZIndex(-1);
    }
    group.setAttrs({...options?.rawBox});
    
    const rect = new Konva.Rect({
      ...this.getRectConfigByType(type),
      x: 0,
      y: 0,
      width: group.width(),
      height: group.height(),
      cornerRadius: 5,
      strokeScaleEnabled: false,
      hitStrokeWidth: 10
    });

    const text = new Konva.Text({
      text: type,
      fontSize: 18 / this.stage.scaleX(),
      padding: 2,
      ellipsis: true,
      width: group.width() - 5,
      height: group.height(),
      // Dont work with Text : https://github.com/konvajs/konva/issues/1370
      strokeScaleEnabled: false,
      fill: 'black',
      fontStyle: 'bold',
    });
    if (options?.rawBox) {
      text.setText(options.rawBox.name);
    }
    this.defineBoxListeners(group, rect, text, {disableUndoRedo: options?.disableUndoRedo ?? false});

    group.add(rect, text);
    this.layer.add(group);
    if (!options?.disableUndoRedo) {
      this.konvaHistory.save(this.layer);
    }
    this.layer.batchDraw();
    
    return group;
  }

  removeBox(id: number) {
    if (id) {
      this.layer.findOne((child: Konva.Shape) => child.getAttr('key') === id)!.destroy();
      this.transformer.nodes([]);
      this.layer.draw();
    }
  }

  /**
   * Set or unset listeners/events from input box.
   * @param box 
   */
  defineBoxListeners(group: Konva.Group, rect: Konva.Rect, text: Konva.Text, options?: {disableUndoRedo: boolean}) {
    // TODO: Snapping lines

    // * SELECT BOX
    if (group.attrs.type === 'bbx') {
      rect.on('contextmenu', (event) => { this.konvaMenu.openMenu(event, this.layer, this.stage, this.transformer, group, this.menu) });
      rect.on('click', () => { 
        this.editorState.selectBoxIdSubject.next(Number(group.getAttr('key')));
      });
    } else {
      group.on('contextmenu', (event) => { this.konvaMenu.openMenu(event, this.layer, this.stage, this.transformer, group, this.menu) });
      group.on('click', () => { 
        this.editorState.selectBoxIdSubject.next(Number(group.getAttr('key')));
      });
    }

    // * HOVER/FOCUS STATE
    group.on('mouseover', () => this.konvaEvent.hoverBox(this.stage, group));
    group.on('mouseleave', () => this.konvaEvent.removeHoverStates(this.stage));

    // * DRAG
    group.on('dragmove', () => {
      this.konvaEvent.constrainBoxMovement(this.stage, this.image, group);
      this.emitUpdatedBox(group);
    });
    if (!options?.disableUndoRedo) {
      group.on('dragstart', () => this.konvaHistory.save(this.layer));
      group.on('dragend', () => this.konvaHistory.save(this.layer));
    }

    // * TRANSFORM
    group.on('transform', () => {
      this.konvaEvent.updateDimensions(group, rect, text);
      this.konvaEvent.constrainBoxMovement(this.stage, this.image, group);
      this.emitUpdatedBox(group);
    });

    // // * EDIT TEXT
    // text.on('dblclick', (event) => {
    //   // For left-click
    //   if (event.evt.button === LEFT_CLICK) {
    //     this.konvaRenameBox.openEditionFrame(this.layer, this.stage, this.transformer, group);
    //   }
    // });
  }

  getNodeCenter(node: Konva.Node): { x: number, y: number } {
    return { x: node.width() / 2, y: node.height() / 2 };
  }

  hideAllBoxes() {
    this.layer.find('Group').forEach(node => node.visible(false));
    this.stage.draw();
  }

  showAllBoxes() {
    this.layer.find('Group').forEach(node => node.visible(true));
    this.stage.draw();
  }

  undo() {
    if (this.konvaHistory.hasPreviousState()) {
      this.stage.removeChildren();
      this.layer = this.konvaHistory.getPreviousState();
      this.stage.add(this.layer);
      this.stage.batchDraw();
    }
  }

  redo() {
    if (this.konvaHistory.hasNextState()) {
      this.stage.removeChildren();
      this.layer = this.konvaHistory.getNextState();
      this.stage.add(this.layer);
      this.stage.batchDraw();
    }
  }

  async generateModel(modelData: ModelData) {
    // Globalize model
    this.model = modelData.model;
    // Image
    this.image = await this.createImage(modelData.image);
    this.editorState.imageSubject.next(this.image);
    this.layer.add(this.image);
    // Boxes
    this.model.rawBoxes?.forEach((rawBox: RawBox, index: number) => {
      const box: Konva.Group = this.createBox(rawBox.type, {rawBox, disableUndoRedo: true});
    });
  }

  async createImage(base64StringImage: string): Promise<Konva.Image> {
    return new Promise((resolve, reject) => {
      Konva.Image.fromURL(base64StringImage, (image) => {
        if (image) {
          image.x(0);
          image.y(0);
          resolve(image);
        } else {
          reject('Erreur lors du chargement de l\'image');
        }
      });
    });
  }

  center() {
    this.image.position({
      x: (this.stage.width() - this.image.width()) / 2,
      y: (this.stage.height() - this.image.height()) / 2,
    });
    this.layer.batchDraw();
  }

  move(direction: DirectionType) {
    let x = this.layer.x();
    let y = this.layer.y();
    switch (direction) {
      case 'top':
        y += this.moveBy;
        break
      case 'bottom':
        y -= this.moveBy;
        break
      case 'right':
        x -= this.moveBy;
        break
      case 'left':
        x += this.moveBy;
        break
    }
    const tween = new Konva.Tween({
      node: this.layer,
      easing: Konva.Easings.StrongEaseOut,
      duration: 0.75,
      x,
      y,
    });
    tween.play();
  }

  // * SELECT
  selectBox(id: number | undefined) {
    this.renderer.setStyle(this.stage.container(), 'cursor', 'move');
    const boxes: Konva.Group[] = this.stage.children[0].find('Group');
    // Unselect others
    boxes.forEach((box) => {
      box.draggable(false);
    });
    let selectedBox = this.findSelectedBox(id);
    if (selectedBox) {
      selectedBox.draggable(true);
      this.transformer.nodes([selectedBox]);

      if (selectedBox.attrs.type !== 'bbx') {
        this.transformer.moveToTop();
      }
    } else {
      this.transformer.nodes([]);
    }
    this.layer.draw();
  }

  findSelectedBox(id: number | undefined): Konva.Group | undefined {
    const groups = this.stage.children[0].find(`Group`);
    if (groups) {
      return groups.find(group => group.getAttr('key') === id) as Konva.Group;
    }
    return;
  }

  updateBox(box: RawBox | undefined) {
    if (!box) return
      const foundBox = this.findSelectedBox(box.key);
      if (foundBox) {
        foundBox.setAttrs({...foundBox.attrs,
          type: box.type,
          name: box.name,
          x: Number(box.x),
          y: Number(box.y),
          width: box.width,
          height: box.height
        });
        const rect = foundBox.findOne('Rect') as Konva.Rect | undefined;
        if (rect) {
          rect.setAttrs({
            ...this.getRectConfigByType(box.type),
            width: foundBox.width(),
            height: foundBox.height()
          });
        }
        const text = foundBox.findOne('Text') as Konva.Text | undefined;
        if (text) {
          text.setText(box.name ?? '');
        }
        this.layer.draw();
        this.stage.draw();
      }
  }

  // * TRANSFORMERS
  transformGroupToBox(group: Konva.Group) {
    return <RawBox>{
      name: group?.attrs.name,
      type: group?.attrs.type,
      x: group?.attrs.x,
      y: group?.attrs.y,
      key: group.attrs.key,
      // zIndex: group.attrs.key,
      width: group?.attrs.width,
      height: group?.attrs.height,
    };
  }

  emitUpdatedBox(group: Konva.Group) {
    this.editorState.updateBoxSubject.next(this.transformGroupToBox(group));
  }
}
