import { Injectable } from '@angular/core';
import { User } from 'src/app/models/user.model';
import { UserCrudService } from './crud/user-crud.service';
import { Observable, Subject, firstValueFrom } from 'rxjs';
import { Config } from '../models/config.model';
import { CustomerCrudService } from './crud/customer-crud.service';
import { Customer } from '../models/customer.model';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private userSubject = new Subject<User>();
  public user?: User;

  get userConfigs() {
    return this.getUserConfigs();
  }

  constructor(
    private userApi: UserCrudService,
    private customerApi: CustomerCrudService,
    private snackbar: MatSnackBar
  )  { }

  public async refreshUser(byMail?: string) {
    let refreshed: boolean = false;
    try {
      const user = await this.setUser(byMail);
      if (user) {
        this.user = user;
        refreshed = true;

        this.userSubject.next(user);
      }
    } catch (error) {
      throw error;
    }
  }

  public getUserState(): Observable<User> {
    return this.userSubject.asObservable();
  }

  /**
   * Retrieve and set or refresh user
   */
  private async setUser(byMail?: string): Promise<User | undefined> {
    let newUser;
    try {
      if (byMail) {
        newUser = await firstValueFrom(this.userApi.getUserByMail(byMail));
      } else {
        newUser = await firstValueFrom(this.userApi.getUserById(this.user!.id));
      }
      newUser.customers = await this.getUserCustomers();
    } catch (error) {
      this.snackbar.open((error as Error).message);
      throw error;
    }
    return newUser;
  }

  /**
   * * Useful for admin page to get customers info of user list
   * Get customers linked to the user with nested objects (Associated projects); 
   * @returns user customers with nested related projects/configs etc.
   */
  async getUserCustomers(otherUser?: User): Promise<Customer[]> {
    const user = otherUser ? otherUser : this.user!;
    let userCustomers: Customer[] = [];
    try {
      if (otherUser && otherUser.customerId && otherUser.id !== this.user?.id) {
        const customer = await firstValueFrom(this.customerApi.getCustomerById(otherUser.customerId));
        userCustomers = [customer];
      } else {
        userCustomers = await firstValueFrom(this.customerApi.getCustomers());
      }
      
    } catch (error) {
      this.snackbar.open((error as Error).message);
    }
    return userCustomers;
  }

  //   /**
  //  * Extract projects from user
  //  * @param user 
  //  * @returns 
  //  */
  //   getUserProjects(otherUser?: User) {
  //     const user = otherUser ? otherUser : this.user!;
  //     const allProjects: Project[] = [];

  //     user.customers?.forEach((customer) => {
  //       customer.projects?.forEach((project) => {
  //         allProjects.push(project);
  //       });
  //     });

  //     return allProjects;
  //   }

  /**
 * Extract all configs from user
 * @param user 
 * @returns 
 */
  getUserConfigs(otherUser?: User) {
    const user = otherUser ? otherUser : this.user!;
    const allConfigs: Config[] = [];

    user.projects?.forEach((project) => {
      project.configs?.forEach((config) => {
        allConfigs.push(config);
      });
    });

    return allConfigs;
  }

  getConfigsByUserCustomers(customers: Customer[]) {
    const allConfigs: Config[] = [];
    customers.forEach(customer => {
      customer.projects?.forEach((project) => {
        project.configs?.forEach((config) => {
          allConfigs.push(config);
        });
      });
    });
    return allConfigs;
  }
}
